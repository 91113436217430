import Box from '@mui/material/Box';

export const FirstGradientBox = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        borderRadius: '50%',
        width: '1028px',
        height: '1028px',
        top: '-200px',
        left: '-620px',
        background:
          'radial-gradient(circle,rgba(200, 113, 253, 0.5) 0%, rgba(200, 113, 253, 0) 50%)',
      }}
    />
  );
};

export const SecondGradientBox = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        borderRadius: '50%',
        width: '728px',
        height: '728px',
        top: '-220px',
        left: '-150px',
        background:
          'radial-gradient(circle,rgba(254, 124, 82, 0.45) 0%, rgba(254, 124, 82, 0) 50%)',
      }}
    />
  );
};

export const ThirdGradientBox = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        borderRadius: '50%',
        width: '1262px',
        height: '1262px',
        top: '-550px',
        right: '-650px',
        opacity: '0px',
        mixBlendMode: 'hard-light',
        background: 'radial-gradient(circle,rgba(82, 96, 254, 0.5) 0%, rgba(82, 96, 254, 0) 50%)',
      }}
    />
  );
};

export const FourthGradientBox = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        borderRadius: '50%',
        width: '519px',
        height: '519px',
        top: '230px',
        left: '-200px',
        background:
          'radial-gradient(circle,rgba(254, 124, 82, 0.45) 0%, rgba(254, 124, 82, 0) 50%)',
        opacity: '50%',
      }}
    />
  );
};

export const FifthGradientBox = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        borderRadius: '50%',
        width: '733px',
        height: '733px',
        top: '250px',
        left: '-500px',
        background:
          'radial-gradient(circle,rgba(200, 113, 253, 0.5) 0%, rgba(200, 113, 253, 0) 50%)',
        opacity: '80%',
      }}
    />
  );
};

export const SixthGradientBox = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        borderRadius: '50%',
        width: '732px',
        height: '732px',
        top: '-228px',
        right: '-400px',
        background:
          'radial-gradient(circle,rgba(200, 113, 253, 0.5) 0%, rgba(200, 113, 253, 0) 50%)',
        opacity: '50%',
      }}
    />
  );
};

import { gradientHeaderBoxContent } from 'consts/content';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import { GradientHeaderBox } from 'components/common/GradientHeaderBox';
import AddModule from 'components/features/AddModule';
import { FeaturesTabsNavigation } from 'components/features/TabsNavigation';

export const FeaturesPage = () => {
  return (
    <>
      <GradientHeaderBox content={gradientHeaderBoxContent.features} />
      <Box bgcolor="#F9FAFB" id="featuresPage">
        <FeaturesTabsNavigation />
        <Outlet />
        <AddModule />
      </Box>
    </>
  );
};

import * as React from 'react';
import { FC } from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { IPopupInfo } from 'components/common/ContactUs';

interface IProps extends DialogProps {
  open: boolean;
  title: string;
  description: string;
  onClose: React.Dispatch<React.SetStateAction<IPopupInfo>>;
}

export const Popup: FC<IProps> = ({ open, title, description, onClose, ...rest }) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose((prevState) => ({ ...prevState, open: false }))}
      PaperProps={{
        sx: {
          width: { xs: '324px', sm: '412px' },
          borderRadius: '15px',
          p: '24px',
          boxSizing: 'border-box',
          top: -200,
          display: 'flex',
          flexDirection: 'column',
          gap: '48px',
        },
      }}
      {...rest}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '16px', sm: '20px', md: '24px' },
            fontWeight: { xs: 700 },
            lineHeight: { xs: '24px', sm: '30px', md: '36px' },
            textAlign: 'left',
            color: theme.palette.text.primary,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '14px', md: '18px' },
            fontWeight: { xs: 500 },
            lineHeight: { xs: '21px', sm: '21px', md: '27px' },
            textAlign: 'left',
            color: theme.palette.text.primary,
            maxWidth: { xs: '276px', sm: '364px' },
          }}
        >
          {description}
        </Typography>
      </Box>
      <Button
        sx={{
          borderRadius: '15px',
          p: '12px 20px',
          maxWidth: { xs: '100%', sm: '113px' },
        }}
        variant="contained"
        color={'primary'}
        onClick={() => {
          onClose((prevState) => ({ ...prevState, open: false }));
        }}
      >
        Закрыть
      </Button>
    </Dialog>
  );
};

import {backgroundGradient} from 'consts/style';
import React, {useEffect, useRef, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import SwiperCore from 'swiper';
import {Navigation} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'index.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {ArrowBackIos, ArrowForwardIos} from '@mui/icons-material';
import {Box, IconButton} from '@mui/material';

interface Tabs {
  title: string;
  path: string;
}

export const TABS: Tabs[] = [
  {
    title: 'Мультивалютные Кошельки',
    path: 'multicurrency',
  },
  {
    title: 'Мобильный Кошелек',
    path: 'mobile-wallet',
  },
  {
    title: 'Выставление Счетов и Кастомизируемые Операции',
    path: 'invoicing',
  },
  {
    title: 'Интеграция Платежных Систем',
    path: 'payment',
  },
  {
    title: 'Операции с Криптовалютами и Управление Цифровыми Активами',
    path: 'crypto',
  },
];
export const FeaturesTabsNavigation = () => {
  const swiperRef = useRef<SwiperCore>();
  const location = useLocation();
  const path = location.pathname;
  const [lastScrollY, setLastScrollY] = useState(0);
  const handleScroll = () => {
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    if (swiperRef.current && path === '/features/crypto') {
      swiperRef.current.slideTo(swiperRef.current.slides.length - 1, 0);
    } else if (swiperRef.current && path === '/features/multicurrency') {
      swiperRef.current.slideTo(0, 0);
    } else if (swiperRef.current && path === '/features/mobile-wallet') {
      swiperRef.current.slideTo(1, 0);
    } else if (swiperRef.current && path === '/features/invoicing') {
      swiperRef.current.slideTo(2, 0);
    } else if (swiperRef.current && path === '/features/payment') {
      swiperRef.current.slideTo(3, 0);
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [path]);
  return (
    <Box
      sx={{
        position: 'sticky',
        zIndex: 50,
        top: 0,
        width: '100%',
        backgroundColor: 'white',
        transitionDuration: '0.4s',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          maxWidth: '1308px',
          minHeight: '40px',
          margin: 'auto',
          display: 'flex',
        }}
      >
        <IconButton
          className="swiper_button_prev"
          sx={{
            position: 'absolute',
            top: '25px',
            left: 0,
            transform: 'translateY(-50%)',
            zIndex: 1,
          }}
          color="primary"
        >
          <ArrowBackIos/>
        </IconButton>
        <Swiper
          onSwiper={(swiper: any) => {
            swiperRef.current = swiper;
          }}
          slidesPerView={'auto'}
          spaceBetween={3}
          loop={false}
          navigation={{
            prevEl: '.swiper_button_prev',
            nextEl: '.swiper_button_next',
          }}
          modules={[Navigation]}
          style={{
            maxWidth: '1228px',
            margin: '0 40px',
          }}
        >
          {TABS.map((tab, i) => (
            <SwiperSlide
              key={i}
              style={{
                width: 'auto',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px 8px 4px 8px',
              }}
            >
              <NavLink
                style={({isActive}) => ({
                  textDecoration: 'none',
                  color: isActive ? 'white' : 'black',
                  background: isActive ? backgroundGradient : 'transparent',
                  borderRadius: '10px',
                  padding: '8px 16px 8px 16px',
                  boxSizing: 'border-box',
                  height: '40px',
                  border: 'none',
                  maxWidth: 'fit-content',
                  whiteSpace: 'nowrap',
                  fontSize: '16px',
                  fontWeight: isActive ? 700 : 400,
                  lineHeight: '24px',
                  textAlign: 'left',
                })}
                to={`${tab.path}${lastScrollY >= 250 ? '#featuresPage' : ''}`}
                onClick={() => {
                  swiperRef.current.slideTo(i, 0);
                }}
              >
                {tab.title}
              </NavLink>
            </SwiperSlide>
          ))}
        </Swiper>
        <IconButton
          className="swiper_button_next"
          sx={{
            position: 'absolute',
            top: '25px',
            right: {xs: 0, md: 10},
            transform: 'translateY(-50%)',
            zIndex: 1,
          }}
          color="primary"
        >
          <ArrowForwardIos/>
        </IconButton>
      </Box>
    </Box>
  );
};

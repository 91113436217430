import React from 'react';
import {parseLinks} from 'utils/parseLinks';

import {Box, Typography} from '@mui/material';
import {Link} from "react-router-dom";
import {theme} from "theme";
import {PRIVACY_POLICY_PARAGRAPHS, PRIVACY_POLICY_PARAGRAPHS_LINKS} from './consts';

export const PrivacyPolicyPage = () => {
  return (
    <Box sx={{p: '100px 24px'}}>
      <Box sx={{maxWidth: '1303px', margin: 'auto'}}>
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: 700,
            lineHeight: '41.6px',
            textAlign: 'center',
          }}
        >
          Политика конфиденциальности
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '21px',
            textAlign: 'left',
            mt: '56px'
          }}
        >
          Последнее обновление: 1 мая 2024 г.
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            textAlign: 'left',
          }}
        >
          Добро пожаловать на
          <Link
            to='https://www.fintechsdk.ru/'
            style={{
              color: theme.palette.text.primary
            }}> FinTechSDK.ru</Link>
          <br/>
          Настоящая Политика конфиденциальности описывает, как <Link
          to='https://www.fintechsdk.ru/'
          style={{
            color: theme.palette.text.primary
          }}>FinTechSDK.ru</Link>,{`управляемый и принадлежащий ООО "ТЕЗЛ" (далее "Мы", "Нас", "Наш"), собирает,
          использует и защищает вашу информацию при использовании нашей платформы ("Платформа"). Используя Платформу, вы
          соглашаетесь с условиями этой Политики конфиденциальности.`}

        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '24px', mt: '24px'}}>
          {PRIVACY_POLICY_PARAGRAPHS.map((paragraph) => (
            <Box key={paragraph.title}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '28px',
                  textAlign: 'left',
                }}
              >
                {paragraph.title}
              </Typography>
              <Box sx={{display: 'flex', flexDirection: 'column', mt: '16px', gap: '8px'}}>
                {paragraph.description.map((item) => (
                  <Typography
                    key={item}
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '21px',
                      textAlign: 'left',
                    }}
                  >
                    {parseLinks(item, PRIVACY_POLICY_PARAGRAPHS_LINKS)}
                  </Typography>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

import { useGSAP } from '@gsap/react';
import { gradientHeaderBoxContent } from 'consts/content';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import React from 'react';

import { Box } from '@mui/material';

import { GradientHeaderBox } from 'components/common/GradientHeaderBox';

import { PortfolioProjects } from './Projects';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

export const PortfolioPage = () => {
  useGSAP(() => {
    document.querySelectorAll('.portfolio_page_titles').forEach((el) => {
      gsap.from(el, {
        scrollTrigger: {
          trigger: el,
          start: '200 bottom',
          end: '200 bottom',
          toggleActions: 'play none none reverse',
        },
        scale: 0.7,
        opacity: 0,
        duration: 0.6,
      });
    });
  });

  return (
    <Box>
      <GradientHeaderBox content={gradientHeaderBoxContent.portfolio} />
      <PortfolioProjects />
    </Box>
  );
};

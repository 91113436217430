import { useMediaSize } from 'hooks/useDeviceDetector';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { scrollHandler } from 'utils/scrollHandler';

import styles from './AppBar.module.css';

import { AppBar, Box, Slide, Toolbar, Typography, useTheme } from '@mui/material';

import Button from 'components/common/Button';

import { ReactComponent as Arrow } from 'assets/appBar/arrowInBtn.svg';
import { ReactComponent as Logo } from 'assets/appBar/logo.svg';
import { ReactComponent as LogoAll } from 'assets/appBar/logoAll.svg';

import { PopupBurger } from '../PopupBurger';

interface IPage {
  text: string;
  link: string;
}

export const pages: IPage[] = [
  { text: 'Главная', link: '' },
  { text: 'Функции', link: 'features' },
  { text: 'С кем работаем?', link: 'serve' },
  { text: 'Портфолио', link: 'portfolio' },
  { text: 'О нас', link: 'about' },
  { text: 'FAQ', link: 'faq' },
];

export const CustomAppBar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = location.pathname;
  const [trigger, setTrigger] = useState<boolean>(false);
  const { isMobileDevice } = useMediaSize();
  const [isBackground, setIsBackgroundVisible] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > lastScrollTop) {
      setTrigger(true);
    } else if (scrollTop < lastScrollTop) {
      setTrigger(false);
    }
    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        setTimeout(() => {
          setTrigger(true);
        }, 0);
        history.replaceState(null, '', window.location.pathname + window.location.search);
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop < 1) {
        setIsBackgroundVisible(false);
      } else {
        setIsBackgroundVisible(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Slide appear={false} direction="down" in={!trigger} timeout={400}>
      <AppBar
        sx={{
          position: 'fixed',
          top: 0,
          height: '88px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transform: isBackground ? 'translateY(-100%)' : 'translateY(0)',
          backgroundColor: isBackground
            ? 'white'
            : currentPage === '/' ||
                currentPage === '/about' ||
                currentPage === '/faq' ||
                currentPage === '/privacy-policy' ||
                currentPage === '/terms-conditions' ||
                currentPage === '/cookies-settings'
              ? 'transparent'
              : 'rgba(255, 255, 255, 0.25)',
          p: '0 24px',
        }}
      >
        <Toolbar
          sx={{
            width: '100%',
            maxWidth: '1307px',
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '@media (min-width: 600px)': {
              p: 0,
            },
            p: 0,
          }}
        >
          <Box
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('');
            }}
          >
            {currentPage === '/' ||
            currentPage === '/about' ||
            currentPage === '/faq' ||
            currentPage === '/privacy-policy' ||
            currentPage === '/terms-conditions' ||
            currentPage === '/cookies-settings' ||
            isBackground ? (
              <Logo />
            ) : (
              <LogoAll />
            )}
          </Box>

          <Box
            sx={{
              width: '100%',
              maxWidth: '600px',
              height: '28px',
              gap: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {!isMobileDevice &&
              pages.map((page) => (
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? '#5260FE' : '',
                    borderBottom: isActive ? '3px #5260FE solid' : '',
                  })}
                  className={`${styles.nav_link} ${
                    isBackground
                      ? styles.all
                      : currentPage === '/' ||
                          currentPage === '/about' ||
                          currentPage === '/faq' ||
                          currentPage === '/privacy-policy' ||
                          currentPage === '/terms-conditions' ||
                          currentPage === '/cookies-settings'
                        ? styles.all
                        : styles.home
                  }`}
                  key={page.text}
                  to={page.link}
                  onClick={(e) => {
                    scrollHandler(e, page.link);
                  }}
                >
                  {page.text}
                </NavLink>
              ))}
          </Box>
          {!isMobileDevice && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: '178px',
                height: '48px',
                border: `1px ${theme.palette.primary.main} solid`,
                borderRadius: '15px',
                padding: '12px 20px 12px 20px',
              }}
              onClick={(e) => {
                scrollHandler(e, 'demo');
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  textAlign: 'center',
                }}
              >
                Запросить демо
              </Typography>
              <Arrow />
            </Button>
          )}
          {isMobileDevice && (
            <PopupBurger
              isBackground={isBackground}
              anchorEl={anchorEl}
              handleClick={handleClick}
              handleClose={handleClose}
            />
          )}
        </Toolbar>
      </AppBar>
    </Slide>
  );
};

import {yupResolver} from '@hookform/resolvers/yup';
import axios from 'axios';
import React, {useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';

import {Box, Typography, useTheme} from '@mui/material';
import Dialog from '@mui/material/Dialog';

import {ReactComponent as EmailIcon} from 'assets/contactUs/emailIcon.svg';
import {ReactComponent as LocationIcon} from 'assets/contactUs/locationIcon.svg';
import {ReactComponent as PhoneIcon} from 'assets/contactUs/phoneIcon.svg';

import Button from '../Button';
import {CustomCheckbox} from '../Checkbox';
import {LinkToMailPhone} from '../LinkToMailPhone';
import {Popup} from '../Popup';
import TextInput from '../TextInput';
import {CONTACT_SCHEMA} from './validation';

interface FormValues {
  name: string;
  email: string;
  phone?: string;
  message: string;
  accept: boolean;
}

export interface IPopupInfo {
  open: boolean;
  title: string;
  description: string;
}

export const ContactUs = () => {
  const [popupInfo, setPopupInfo] = useState<IPopupInfo>({
    open: false,
    title: '',
    description: '',
  });
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const [isDisabledCaptcha, setIsDisabledCaptcha] = useState<boolean>(false);
  const theme = useTheme();
  const {
    handleSubmit,
    formState: {errors},
    control,
    reset,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(CONTACT_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
      accept: false,
    },
  });
  const onSubmit = async (contacts: FormValues) => {
    try {
      if (recaptchaToken) {
        setIsDisabledButton(true);
        const dataToSend = {
          name: contacts.name,
          email: contacts.email,
          phone: contacts.phone,
          message: contacts.message,
        };
        const formData = new FormData();
        Object.keys(dataToSend).forEach((key) => {
          const value = dataToSend[key as keyof typeof dataToSend];
          if (typeof value !== 'undefined') {
            formData.append(key, value);
          }
        });
        formData.append('g-recaptcha-response', recaptchaToken);
        await axios.post('https://www.fintechsdk.ru/sendmail/sendemail.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setIsDisabledButton(false);
        reset();
        setPopupInfo({
          open: true,
          title: 'Форма отправлена',
          description: 'Спасибо, что связались с нами. Мы свяжемся с Вами в скором времени',
        });
        reset();
      } else {
        setIsDisabledCaptcha(true);
      }
    } catch (error: any) {
      setIsDisabledButton(false);
      if (axios.isAxiosError(error) && error?.response) {
        const messageError = error.response?.data?.message;
        if (messageError === 'Капча не пройдена, пожалуйста, попробуйте еще раз') {
          setRecaptchaToken(null);
          setIsDisabledCaptcha(true);
        } else {
          setPopupInfo({
            open: true,
            title: 'Форму не удалось отправить',
            description: messageError || 'Попробуйте позже',
          });
        }
      } else {
        setPopupInfo({
          open: true,
          title: 'Форму не удалось отправить',
          description: 'Попробуйте позже',
        });
      }
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    const filteredValue = inputValue.replace(/[^+\d]/g, '').replace(/(?!^)\+/g, '');

    setValue('phone', filteredValue);
  };
  return (
    <Box
      sx={{
        minHeight: {xs: '1000px', sm: '892px', md: '902px'},
        padding: {
          xs: '72px 16px 36px 16px',
          sm: '72px 20px 36px 20px',
          md: '100px 24px 50px 24px',
        },
        boxSizing: 'border-box',
        background: 'white',
      }}
      id="contact"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '1308px',
          margin: 'auto',
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              minHeight: '132px',
            }}
          >
            <Typography
              sx={{
                fontSize: {xs: '30px', sm: '32px', md: '52px'},
                fontWeight: {xs: 700, md: 800},
                lineHeight: {xs: '36px', sm: '41.6px', md: '62.4px'},
                textAlign: 'left',
              }}
            >
              Запрос доступа к демо
            </Typography>
            <Typography
              sx={{
                fontSize: {xs: '16px', sm: '18px'},
                fontWeight: {xs: 600, sm: 500},
                lineHeight: {xs: '24px', sm: '27px'},
                textAlign: 'left',
              }}
            >
              Мы вышлем Вам доступы в течение одного рабочего дня, после того, как Вы заполните
              форму обратной связи
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            minHeight: '564px',
            display: 'flex',
            flexDirection: {xs: 'column', sm: 'row'},
            gap: '48px',
            justifyContent: 'space-between',
            mt: '56px',
          }}
        >
          <Box
            sx={{
              width: {xs: '100%', sm: '50%'},
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <Typography
                sx={{
                  fontSize: {xs: '24px', md: '40px'},
                  fontWeight: 700,
                  lineHeight: {xs: '33px', md: '48px'},
                  textAlign: 'left',
                }}
              >
                Свяжитесь с нами
              </Typography>
              <Typography
                sx={{
                  fontSize: {xs: '14px', md: '18px'},
                  fontWeight: 400,
                  lineHeight: {xs: '21px', md: '27px'},
                  textAlign: 'left',
                }}
              >
                Мы будем рады предоставить Вам консультацию по нашей финтех-платформе
              </Typography>
            </Box>
            <Box sx={{mt: '32px'}}>
              <LinkToMailPhone
                action="mailto"
                image={<EmailIcon/>}
                title="sales@fintechsdk.ru"
              />
              <LinkToMailPhone action="tel" image={<PhoneIcon/>} title="+79787437888"/>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  gap: '16px',
                  mt: '18px',
                  alignItems: 'center',
                }}
              >
                <LocationIcon width="19px"/>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left',
                  }}
                >
                  Крым Респ, г.о. Симферополь, г Симферополь, ул Набережная
                  <br/>
                  имени 60-летия СССР, зд. 75С, помещ. 401
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              width: {xs: '100%', sm: '50%'},
              maxWidth: {xs: '100%', sm: '642px'},
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              alignItems: {xs: 'center', sm: 'start'},
              gap: '20px',
            }}
          >
            <TextInput name="name" label="Имя" errors={errors} control={control}/>
            <TextInput name="email" label="Почта" errors={errors} control={control}/>
            <TextInput
              name="phone"
              label="Телефон"
              errors={errors}
              control={control}
              onChangeHandler={handleChange}
            />
            <TextInput
              name="message"
              label="Сообщение"
              errors={errors}
              control={control}
              multiline
              rows={3}
            />
            <Box>
              <CustomCheckbox
                name="accept"
                label="Я принимаю Условия"
                errors={errors}
                control={control}
              />
              <Typography
                sx={{
                  textDecoration: 'none',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '18px',
                  textAlign: 'left',
                }}
              >
                Нажимая кнопку «Отправить», вы подтверждаете, что прочитали и принимаете нашу
                <Link
                  to="/privacy-policy"
                  style={{
                    textDecoration: 'none',
                    margin: '0 3px',
                    color: theme.palette.text.secondary,
                  }}
                >
                  Политику конфиденциальности
                </Link>
                и
                <Link
                  to="/terms-conditions"
                  style={{
                    textDecoration: 'none',
                    margin: '0 3px',
                    color: theme.palette.text.secondary,
                  }}
                >
                  Условия использования.
                </Link>
              </Typography>
            </Box>

            <Box
              sx={{
                display: {xs: 'column', md: 'flex'},
                gap: '10px',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Button
                sx={{
                  minWidth: {xs: '100%', sm: '161px'},
                  height: '48px',
                  borderRadius: '15px',
                  mb: '10px',
                }}
                type="submit"
                disabled={isDisabledButton}
              >
                Отправить
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={isDisabledCaptcha && !recaptchaToken}
        onClose={() => setIsDisabledCaptcha(false)}
        PaperProps={{
          sx: {
            width: '298px',
            height: '73px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            m: 0,
            p: 0,
          },
        }}
      >
        <ReCAPTCHA
          sitekey="6LckSuMpAAAAAKk6XbUAVlFOb-FXrDUslH4YsJVO"
          onChange={(e) => setRecaptchaToken(e)}
          size="normal"
        />
      </Dialog>
      <Popup
        open={popupInfo.open}
        title={popupInfo.title}
        description={popupInfo.description}
        onClose={setPopupInfo}
      />
    </Box>
  );
};

import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

interface IProps {
  image: string;
  title: string;
  description: string;
  hash: string;
}

export const ServeCard: FC<IProps> = ({ image, title, description, hash }) => {
  const navigate = useNavigate();
  return (
    <Box
      className={'home_serve_images'}
      sx={{
        maxWidth: '100%',
        height: '313px',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        zIndex: 2,
      }}
    >
      <Box
        sx={{ cursor: 'pointer' }}
        component="img"
        width="150px"
        height="150px"
        onClick={() => {
          navigate(`/serve#${hash}`);
        }}
        src={image}
      />
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 700,
          lineHeight: '33.6px',
          textAlign: 'left',
          marginTop: '16px',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '21px',
          textAlign: 'left',
          marginTop: '8px',
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

import {backgroundGradient} from 'consts/style';
import React, {FC} from 'react';
import LazyLoad from 'react-lazyload';
import {useNavigate} from 'react-router-dom';

import {Box, Typography, useTheme} from '@mui/material';

interface IProps {
  image: string;
  title: string;
  description: string;
  tags: string[];
}

export const PortfolioCard: FC<IProps> = ({image, title, description, tags}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      className={'home_portfolio_projects'}
      sx={{
        maxWidth: {xs: '343px', sm: '354px', md: '422px'},
        height: {xs: '491px', sm: '499px', md: '564px'},
        borderRadius: '20px',
        border: `1px ${theme.palette.primary.main} solid`,
        padding: '24px',
        boxSizing: 'border-box',
        background: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: {md: 1},
        zIndex: 10,
      }}
    >
      <LazyLoad height={400} offset={100} once>
        <Box
          component="img"
          src={image}
          alt={title}
          height="auto"
          maxWidth={'100%'}
          sx={{width: {xs: '295px', sm: '306px', md: '372px'}, cursor: 'pointer'}}
          onClick={() => {
            navigate(`/portfolio#${title.replace(/\s+/g, '').toLowerCase()}`);
          }}
        />
      </LazyLoad>
      <Box>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '33.6px',
            textAlign: 'left',
            marginTop: {xs: '16px', md: '27px'},
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            textAlign: 'left',
            marginTop: '8px',
          }}
        >
          {description}
        </Typography>
        <Box
          sx={{
            marginTop: {xs: '16px', md: '35.5px'},
            display: 'flex',
            gap: '8px',
            flexWrap: 'wrap',
          }}
        >
          {tags.map((tag, ind) => (
            <Box
              key={ind}
              sx={{
                background: backgroundGradient,
                borderRadius: '20px',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px 6px 4px 6px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '18px',
                  textAlign: 'left',
                }}
              >
                {tag}
              </Typography>
            </Box>
          ))}
        </Box>

      </Box>

    </Box>
  );
};

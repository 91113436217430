import React from 'react';

import { Box, Typography } from '@mui/material';
import {Link} from "react-router-dom";
import { TERMS_CONDITIONS_PARAGRAPHS } from './consts';
import {theme} from "theme";
export const TermsConditionsPage = () => {
  return (
    <Box sx={{ p: '100px 24px' }}>
      <Box sx={{ maxWidth: '1303px', margin: 'auto' }}>
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: 700,
            lineHeight: '41.6px',
            textAlign: 'center',
          }}
        >
          Условия использования платформы <Link
          to='https://www.fintechsdk.ru/'
          style={{
            color: theme.palette.text.primary
          }}>FinTechSDK.ru.</Link>
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '21px',
            textAlign: 'left',
            mt: '56px',
          }}
        >
          Последнее обновление: 1 мая 2024 г.
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            textAlign: 'left',
          }}
        >
          Добро пожаловать на
          <Link
            to='https://www.fintechsdk.ru/'
            style={{
              color: theme.palette.text.primary
            }}> FinTechSDK.ru.</Link>
          <br/>
          {`Эта платформа ("Платформа") управляется и принадлежит ООО "ТЕЗЛ", адрес Крым Респ, г.о. Симферополь, г Симферополь, ул Набережная имени 60-летия СССР, зд. 75С, помещ. 401.`}
          <Link
            to='https://www.fintechsdk.ru/'
            style={{
              color: theme.palette.text.primary
            }}> FinTechSDK.ru</Link> - это платформа финтех, доступная как в виде мобильного приложения, так и через
          веб-интерфейс. Используя Платформу, вы соглашаетесь с этими Условиями использования {`("Условия")`}. Если вы
          не
          согласны с этими Условиями, пожалуйста, не используйте Платформу.

        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', mt: '24px' }}>
          {TERMS_CONDITIONS_PARAGRAPHS.map((paragraph) => (
            <Box key={paragraph.title}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '28px',
                  textAlign: 'left',
                }}
              >
                {paragraph.title}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', mt: '16px', gap: '8px' }}>
                {paragraph.description.map((item) => (
                  <Typography
                    key={item}
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '21px',
                      textAlign: 'left',
                    }}
                  >
                    {item}
                  </Typography>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useMediaSize } from 'hooks/useDeviceDetector';
import React from 'react';

import { Box } from '@mui/material';

import BackgroundAnimation from 'components/common/Balls';

import { HomeAbout } from './About';
import { HomeFAQ } from './FAQ';
import { HomeFirst } from './First';
import { HomeMobile } from './Mobile';
import { HomePortfolio } from './Portfolio';
import { HomeServe } from './Serve';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

export const HomePage = () => {
  const { isPhone, isMobileDevice } = useMediaSize();
  useGSAP(() => {
    //FIRST SECTION
    const tl = gsap.timeline();
    tl.from('.home_first_title', {
      yPercent: 100,
      opacity: 0,
      duration: 1,
      delay: 1,
    }).from(['.home_first_subtitle', '.home_first_sub_subtitle', '.home_first_button'], {
      opacity: 0,
      duration: 1,
    });
    //SERVE SECTION
    gsap.from('.home_serve_title', {
      scrollTrigger: {
        trigger: '.home_serve_title',
        start: '200 bottom',
        end: 'bottom top',
        toggleActions: 'play none none reverse',
      },
      scale: 1.2,
      opacity: 0,
      duration: 0.5,
    });
    if (!isMobileDevice) {
      gsap.from('.home_serve_images', {
        scrollTrigger: {
          trigger: '.home_serve_images',
          start: '100 bottom',
          end: '500 200',
          toggleActions: 'play none none reverse',
        },
        stagger: 0.35,
        scale: 0,
        opacity: 0,
        duration: 0.5,
        delay: 0.5,
      });
    } else {
      document.querySelectorAll('.home_serve_images').forEach((el) => {
        gsap.from(el, {
          scrollTrigger: {
            trigger: el,
            start: '20 bottom',
            end: 'bottom 200',
            toggleActions: 'play none none reverse',
          },
          stagger: 0.35,
          scale: 0,
          opacity: 0,
          duration: 0.5,
          delay: 0.5,
        });
      });
    }
    //PORTFOLIO SECTION
    gsap.from('.home_portfolio_titles', {
      scrollTrigger: {
        trigger: '.home_portfolio_section',
        start: '300 bottom',
        end: '300 200',
        toggleActions: 'play none none reverse',
      },
      scale: 1.5,
      opacity: 0,
      duration: 0.5,
    });
    if (!isMobileDevice) {
      gsap.from('.home_portfolio_projects', {
        scrollTrigger: {
          trigger: '.home_portfolio_projects',
          start: '100 bottom',
          end: 'bottom 200',
          toggleActions: 'play none none reverse',
        },
        scale: 0.5,
        opacity: 0,
        stagger: 0.25,
        duration: 0.5,
      });
    } else {
      document.querySelectorAll('.home_portfolio_projects').forEach((el) => {
        gsap.from(el, {
          scrollTrigger: {
            trigger: el,
            start: 'top bottom',
            end: 'bottom 200',
            toggleActions: 'play none none reverse',
          },
          scale: 0.5,
          opacity: 0,
          stagger: 0.25,
          duration: 0.5,
        });
      });
    }
    //ABOUT SECTION
    if (!isMobileDevice) {
      gsap.from('.home_about_image', {
        scrollTrigger: {
          trigger: '.home_about_section',
          start: '500 bottom',
          end: 'bottom 200',
          toggleActions: 'play none none reverse',
        },
        scale: 0.5,
        stagger: 0.2,
        opacity: 0,
        duration: 0.5,
      });
    } else {
      gsap.from('.home_about_image', {
        scrollTrigger: {
          trigger: '.home_about_image',
          start: '100 bottom',
          end: 'bottom 200',
          toggleActions: 'play none none reverse',
        },
        scale: 0.5,
        stagger: 0.2,
        opacity: 0,
        duration: 0.5,
      });
    }
    gsap.from('.home_about_bottomarea', {
      scrollTrigger: {
        trigger: '.home_about_bottomarea',
        start: '300 bottom',
        end: 'top  bottom',
        toggleActions: 'play none none none',
      },
      stagger: 0.1,
      scale: 0.5,
      opacity: 0,
    });
    gsap.to('.home_mobile_section', {
      scrollTrigger: {
        trigger: '.home_first_section',
        start: 'top top',
        end: `+=1100px`,
        scrub: true,
        pin: window.innerHeight > 811 ? '.home_first_section' : false,
        pinSpacing:false
      },
    });
  });
  return (
    <Box sx={{ overflowX: 'clip', overflowY: 'visible', position: 'relative' }}>
      <HomeFirst />
      <HomeMobile />
      <Box sx={{ position: 'relative', zIndex: 2, backgroundColor: '#fff'}}>
        <BackgroundAnimation speed={isPhone ? 100 : 150} blur={isPhone ? 90 : 190} diameter={isPhone ? 250 : 500} countOfBalls={isPhone ? 5 : 8} zIndex={1} />
        <HomeServe />
        <HomePortfolio />
        <HomeAbout />
      </Box>
      <HomeFAQ />
    </Box>
  );
};

import {
  FirstGradientBox,
  SecondGradientBox,
  ThirdGradientBox,
} from 'components/gradient-boxes/ForFeaturePage';

import FirstImg from 'assets/features/payment/firstImg.png';
import SecondImg from 'assets/features/payment/secondImg.png';
import ThirdImg from 'assets/features/payment/thirdImg.png';
import TriagleUpRight from 'assets/features/triangles/triangleUpRight.png';
import TriangleDownRight from 'assets/features/triangles/triangleDownRight.png';
import TriangleDownLeft from 'assets/features/triangles/triangleDownLeft.png';

export const headerInfoForFeaturesPages = {
  info: 'Готовые интеграции со Stripe, PayPal, Wise и др., обеспечивающие гибкость и возможность обработки транзакций в разных валютах в соответствии с требованиями клиента.',
  title: 'Интеграции платежных систем',
  details: 'Выберите подходящую платежную систему',
};

export const contentInfoForFeaturesPages = [
  {
    title: 'Управление платежными шлюзами',
    description: [
      'Настройте параметры шлюзов в соответствии с вашими потребностями, используя основные метрики Вашего бизнеса для бесшовной интеграции и управления платежными сервисами.',
    ],
    image: FirstImg,
    imageWidth: {xs: '310px', sm: '583px', md: '601px'},
    imageHeight: {xs: '223px', sm: '419px', md: '440px'},
    gradient: (
      <>
        <FirstGradientBox/>
        <SecondGradientBox/>
      </>
    ),
    triangle: TriagleUpRight
  },
  {
    title: 'Поддержка множества валют',
    description: [
      'Обеспечивает проведение транзакций в основных фиатных валютах, таких как USD и EUR, а также гибкость в управлении валютными настройками через интегрированные системы -  Stripe и др.',
    ],
    image: SecondImg,
    imageWidth: {xs: '310px', sm: '584px', md: '621px'},
    imageHeight: {xs: '237px', sm: '446px', md: '476px'},
    triangle: TriangleDownLeft
  },
  {
    title: 'Безопасность и масштабируемость',
    description: [
      'Каждая услуга в рамках платформы настраивается для оптимальной производительности, обеспечивая безопасную и эффективную обработку транзакций.',
    ],
    image: ThirdImg,
    imageWidth: {xs: '310px', sm: '584px', md: '554px'},
    imageHeight: {xs: '247px', sm: '467px', md: '432px'},
    gradient: <ThirdGradientBox/>,
    triangle: TriangleDownRight
  },
];

export const startupsMethodologiesInfo = [
  {
    id: '1',
    title: 'Консультации и стратегическое планирование:',
    text: 'Предоставление советов по стратегическому развитию, определение бизнес-модели и разработка долгосрочных целей.'
  },
  {
    id: '2',
    title: 'Адаптация и настройка:',
    text: 'Адаптация и настройка наших решений для удовлетворения уникальных потребностей и целей стартапа, включая разработку и тестирование минимально жизнеспособного продукта (MVP).'
  },
  {
    id: '3',
    title: 'Запуск и поддержка:',
    text: 'Помощь в запуске и продвижении продукта, а также предоставление технической и стратегической поддержки на всех этапах развития.'
  },
]

export const enterprisesMethodologiesInfo = [
  {
    id: '1',
    title: 'Анализ:',
    text: 'Проведение всестороннего анализа текущих финансовых процессов и операций предприятия для выявления узких мест и потенциальных возможностей для оптимизации.'
  },
  {
    id: '2',
    title: 'Консультирование:',
    text: 'Предоставление стратегических советов по оптимизации финансовых процессов, повышению эффективности и снижению затрат.'
  },
  {
    id: '3',
    title: 'Определение бизнес-потребностей:',
    text: 'Взаимодействие с руководством бизнеса для определения конкретных финансовых целей и потребностей.'
  },
  {
    id: '4',
    title: 'Настройка:',
    text: 'Настройка наших решений и интеграция с существующими системами предприятия для оптимальной производительности и соответствия требованиям.'
  },
  {
    id: '5',
    title: 'Запуск:',
    text: 'Подготовка и запуск новых финансовых решений с минимальным вмешательством в текущие бизнес-операции:'
  },
  {
    id: '6',
    title: 'Поддержка:',
    text: 'Предоставление постоянной технической и стратегической поддержки, мониторинг результатов и внесение корректировок в стратегию по мере необходимости.'
  },
]
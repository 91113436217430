import {FC, useState} from 'react';
import {scrollHandler} from 'utils/scrollHandler';

import {Accordion, AccordionDetails, AccordionSummary, Typography, useTheme} from '@mui/material';

import {ReactComponent as PlusImg} from 'assets/home/faq/plus.svg';

interface IProps {
  question: string;
  answers: string[];
}

export const FAQAccordion: FC<IProps> = ({question, answers}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Accordion
      onChange={() => setIsOpen((prev) => !prev)}
      sx={{
        borderRadius: '15px',
        border: `1px solid ${theme.palette.primary.main}`,
        mb: '16px',
        '&.Mui-expanded': {
          margin: '0 0 16px 0',
        },
        '&:before': {
          display: 'none',
        },
        '&:last-of-type': {
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px',
        },
      }}
    >
      <AccordionSummary
        expandIcon={isOpen ? <PlusImg style={{transform: 'rotate(43deg)'}}/> : <PlusImg/>}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          minHeight: '72px',
          '&.Mui-expanded': {
            minHeight: '72px',
          },
        }}
      >
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '27px',
            textAlign: 'left',
          }}
        >
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {answers.map((answer) => (
          <Typography
            key={answer}
            sx={{
              cursor: answer === 'Связаться с нами' ? 'pointer' : '',
              color: answer === 'Связаться с нами' ? theme.palette.primary.main : '',
            }}
            onClick={(e) => answer === 'Связаться с нами' && scrollHandler(e, 'contact')}
          >
            {answer}
            <br/>
            <br/>
          </Typography>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

import { backgroundGradient } from 'consts/style';
import { useMediaSize } from 'hooks/useDeviceDetector';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

export interface GradientHeaderBoxContent {
  title: string;
  details: string;
  src: {
    desktop: string;
    tablet: string;
    mobile: string;
  };
}

interface Props {
  content: GradientHeaderBoxContent;
}

export const GradientHeaderBox: FC<Props> = ({ content }) => {
  const { isPhone, isTablet } = useMediaSize();
  const location = useLocation();
  const currentPage = location.pathname;
  return (
    <Box sx={{ height: { xs: '273px', sm: '282px', md: '378px' }, width: 'calc(100vw - 10px)' }}>
      <Box
        sx={{
          height: { xs: '273px', sm: '282px', md: '378px' },
          width: 'calc(100vw - 10px)',
          position: 'absolute',
          top: 0,
          left: 0,
          boxSizing: 'border-box',
          backgroundImage: backgroundGradient,
          zIndex: -2,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: 'calc(100vw - 10px)',
            height: '100%',
            zIndex: -1,
          }}
          component={'img'}
          src={isPhone ? content.src.mobile : isTablet ? content.src.tablet : content.src.desktop}
        />
        <Box
          sx={{
            maxWidth: '1308px',
            height: '182px',
            margin: 'auto',
            marginTop: { xs: '108px', sm: '121px', md: '145px' },
            px: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '36px', sm: '36px', md: '52px' },
              fontWeight: { xs: 700, sm: 800 },
              lineHeight: { xs: '43.2px', sm: '43px', md: '62.4px' },
              '@media (max-width: 515px)':
                currentPage.split('/')[1] === 'features'
                  ? {
                      fontSize: 29,
                      lineHeight: '36px',
                    }
                  : '',
              '@media (max-width: 425px)':
                currentPage.split('/')[1] === 'features'
                  ? {
                      fontSize: 25,
                      lineHeight: '36px',
                    }
                  : '',
              textAlign: 'center',
              color: 'rgba(255, 255, 255, 1)',
            }}
          >
            {content.title}
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
              textAlign: 'center',
              marginTop: '8px',
              color: 'rgba(255, 255, 255, 1)',
            }}
          >
            {content.details}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { CustomAppBar } from 'components/common/AppBar';
import { ContactUs } from 'components/common/ContactUs';
import { DemoTest } from 'components/common/DemoTest';
import { Footer } from 'components/common/Footer';

import CryptoFirstImg from 'assets/features/crypto/firstImg.png';
import InvoceFirstImg from 'assets/features/invoicing/firstImg.png';
import MobileFirstImg from 'assets/features/mobile/firstImg.png';
import MulticurencyFirstImg from 'assets/features/multicurrency/firstImg.png';
import PaymentFirstImg from 'assets/features/payment/firstImg.png';
import AboutFifthImg from 'assets/home/about/fifthImg.png';
import AboutFirstImg from 'assets/home/about/firstImg.png';
import AboutFourthImg from 'assets/home/about/fourthImg.png';
import AboutSecondImg from 'assets/home/about/secondImg.png';
import AboutThirdImg from 'assets/home/about/thirdImg.png';
import fifthScreenshoot from 'assets/home/first/fifthScreenshoot.png';
import firstScreenshoot from 'assets/home/first/firstScreenshoot.png';
import fourthScreenshoot from 'assets/home/first/fourthScreenshoot.png';
import secondScreenshoot from 'assets/home/first/secondScreenshoot.png';
import thirdScreenshoot from 'assets/home/first/thirdScreenshoot.png';
import Add_module_1 from 'assets/home/mobile/add_module/Add_module_1.png';
import Add_module_2 from 'assets/home/mobile/add_module/Add_module_2.png';
import Add_module_3 from 'assets/home/mobile/add_module/Add_module_3.png';
import ConnectorsImg from 'assets/home/mobile/connectors.png';
import Crypto_operation_1 from 'assets/home/mobile/crypto_operation/Crypto_operation_1.png';
import Crypto_operation_2 from 'assets/home/mobile/crypto_operation/Crypto_operation_2.png';
import Invoicing_operations_1 from 'assets/home/mobile/invoicing_operations/Invoicing_operations_1.png';
import Invoicing_operations_2 from 'assets/home/mobile/invoicing_operations/Invoicing_operations_2.png';
import Wrapper from 'assets/home/mobile/mobileWrapper.png';
import Mobile_wallet_1 from 'assets/home/mobile/mobile_wallet/Mobile_wallet_1.png';
import Mobile_wallet_2 from 'assets/home/mobile/mobile_wallet/Mobile_wallet_2.png';
import Mobile_wallet_3 from 'assets/home/mobile/mobile_wallet/Mobile_wallet_3.png';
import Multicurrency_Wallets_1 from 'assets/home/mobile/multicurrency_Wallets/Multicurrency_Wallets_1.png';
import Multicurrency_Wallets_2 from 'assets/home/mobile/multicurrency_Wallets/Multicurrency_Wallets_2.png';
import Payment_system_integrations_1 from 'assets/home/mobile/payment_system_integrations/Payment_system_integrations_1.png';
import Payment_system_integrations_2 from 'assets/home/mobile/payment_system_integrations/Payment_system_integrations_2.png';
import TixnetAvatarImg from 'assets/portfolio/projects/tixnet/tixnetAvatar.png';
import TixnetCard_1_Img from 'assets/portfolio/projects/tixnet/tixnetCard_1.png';
import TixnetCard_2_Img from 'assets/portfolio/projects/tixnet/tixnetCard_2.png';
import TixnetCard_3_Img from 'assets/portfolio/projects/tixnet/tixnetCard_3.png';
import TixnetCard_4_Img from 'assets/portfolio/projects/tixnet/tixnetCard_4.png';
import StartupsImg from 'assets/serve/startups.png';

const MAIN_IMAGES: string[] = [
  fifthScreenshoot,
  firstScreenshoot,
  fourthScreenshoot,
  secondScreenshoot,
  thirdScreenshoot,
  Wrapper,
  Multicurrency_Wallets_1,
  Multicurrency_Wallets_2,
];
const SECONDARY_IMAGES: string[] = [
  Add_module_1,
  Add_module_2,
  Add_module_3,
  ConnectorsImg,
  Crypto_operation_1,
  Crypto_operation_2,
  Invoicing_operations_1,
  Invoicing_operations_2,
  Mobile_wallet_1,
  Mobile_wallet_2,
  Mobile_wallet_3,
  Payment_system_integrations_1,
  Payment_system_integrations_2,
  CryptoFirstImg,
  InvoceFirstImg,
  MobileFirstImg,
  MulticurencyFirstImg,
  PaymentFirstImg,
  StartupsImg,
  TixnetAvatarImg,
  TixnetCard_1_Img,
  TixnetCard_2_Img,
  TixnetCard_3_Img,
  TixnetCard_4_Img,
  AboutFirstImg,
  AboutSecondImg,
  AboutThirdImg,
  AboutFourthImg,
  AboutFifthImg,
];
export const MainLayout = () => {
  const location = useLocation();
  const currentPage = location.pathname;
  const [shouldRender, setShouldRender] = useState(false);
  const preloadImages = (imageUrls: string[]): Promise<void[]> => {
    const promises = imageUrls.map((url) => {
      return new Promise<void>((resolve) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve();
        img.onerror = () => {
          console.error(`Failed to load image: ${url}`);
          resolve();
        };
      });
    });
    return Promise.all(promises);
  };

  useEffect(() => {
    (async () => {
      await preloadImages(MAIN_IMAGES);
      setShouldRender(true);
      await preloadImages(SECONDARY_IMAGES);
    })();
  }, []);
  if (!shouldRender) {
    return null;
  }
  return (
    <Box>
      <CustomAppBar />
      <Outlet />
      {!['/privacy-policy', '/terms-conditions', '/cookies-settings'].includes(currentPage) && (
        <>
          <ContactUs />
          <DemoTest />
        </>
      )}
      <Footer />
    </Box>
  );
};

import {FC} from 'react';

import Typography from '@mui/material/Typography';

import ContentBox from 'components/common/ContentBox';

interface HeaderInfoForFeaturesPages {
  info: string;
  title: string;
  details: string;
}

interface Props {
  headerInfo: HeaderInfoForFeaturesPages;
}

const ContentHeaderForFeaturesPages: FC<Props> = ({headerInfo}) => {
  return (
    <ContentBox
      sx={{
        // mt: {xs: '48px', md: '64px'},
        // mb: {xs: '80px', md: '52px'},
        padding: {
          xs: '48px 16px 80px 16px',
          sm: '48px 20px 80px 20px',
          md: '64px 24px 52px 24px',
        },
        flexDirection: 'column',
        textAlign: 'center',
        zIndex: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: {xs: '12px', sm: '14px'},
          fontWeight: {xs: 300, sm: 400},
          lineHeight: {xs: '18px', sm: '21px'},
        }}
      >
        {headerInfo.info}
      </Typography>
      <Typography
        sx={{
          fontSize: {xs: '30px', sm: '32px', md: '52px'},
          fontWeight: {xs: 700, md: 800},
          lineHeight: {xs: '36px', sm: '41.6px', md: '62.4px'},
          mt: {xs: '8px', sm: '16px'},
        }}
      >
        {headerInfo.title}
      </Typography>
      <Typography
        maxWidth={550}
        sx={{
          fontSize: {xs: '16px', md: '20px'},
          fontWeight: {xs: 400, md: 500},
          lineHeight: {xs: '24px', md: '30px'},
          mt: '8px',
          maxWidth: '550px',
          mx: 'auto',
        }}
      >
        {headerInfo.details}
      </Typography>
    </ContentBox>
  );
};

export default ContentHeaderForFeaturesPages;

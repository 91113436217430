import {
  FifthGradientBox,
  FirstGradientBox,
  FourthGradientBox,
  SecondGradientBox,
  SixthGradientBox,
  ThirdGradientBox,
} from 'components/gradient-boxes/ForFeaturePage';

import FifthImg from 'assets/features/multicurrency/fifthImg.png';
import FirstImg from 'assets/features/multicurrency/firstImg.png';
import FourthImg from 'assets/features/multicurrency/fourthImg.png';
import SecondImg from 'assets/features/multicurrency/secondImg.png';
import SixthImg from 'assets/features/multicurrency/sixthImg.png';
import ThirdImg from 'assets/features/multicurrency/thirdImg.png';
import TriagleUpRight from 'assets/features/triangles/triangleUpRight.png';
import TriangleUpLeft from 'assets/features/triangles/triangleUpLeft.png';
import TriangleDownRight from 'assets/features/triangles/triangleDownRight.png';
import TriangleDownLeft from 'assets/features/triangles/triangleDownLeft.png';

export const headerInfoForFeaturesPages = {
  info: 'Интегрированный с бухгалтерской книгой кошелек, который обеспечивает контроль транзакций и управление множеством счетов для работы с разными валютами или активами',
  title: 'Мультивалютные кошельки',
  details:
    'Создавать любые кошельки так же легко, как строить из Лего: просто, быстро и функционально',
};

export const contentInfoForFeaturesPages = [
  {
    title: 'Создание мультивалютного счета',
    description: [
      'От фиатных валют и криптовалют до баллов лояльности и пользовательских единиц стоимости, наша платформа упрощает управление разнообразными активами.',
      'Мы приглашаем вас воспользоваться нашим мощным транзакционным механизмом для бесшовной интеграции любых видов валют или активов в ваш цифровой кошелек без ограничений по объему или масштабу. Оцените гибкость и безграничные возможности в  управлении активами.',
    ],
    image: FirstImg,
    imageWidth: {xs: '310px', sm: '584px', md: '565px'},
    imageHeight: {xs: '204px', sm: '384px', md: '385px'},
    gradient: (
      <>
        <FirstGradientBox/>
        <SecondGradientBox/>
      </>
    ),
    triangle: TriagleUpRight
  },
  {
    title: 'Пополнение средств',
    description: [
      'Пополните свой кошелек в выбранной вами валюте через внешнюю платежную систему. Обрабатывайте платежи через Stripe, PayPal, Wise и др., а также криптовалюты BTC, ETH и др. или интегрируйте свою платежную систему.',
    ],
    image: SecondImg,
    imageWidth: {xs: '310px', sm: '584px', md: '592px'},
    imageHeight: {xs: '241px', sm: '453px', md: '453px'},
    triangle: TriangleDownLeft
  },
  {
    title: 'Перевод средств',
    description: [
      'С нашей платформой перевод средств между вашими кошельками прост и эффективен. Настройте свои правила, потоки, комиссии и объедините транзакции в одну операцию.',
      'Наслаждайтесь свободой мультивалютных переводов, позволяя отправлять и получать деньги в различных валютах без лишних хлопот.',
    ],
    image: ThirdImg,
    imageWidth: {xs: '310px', sm: '584px', md: '570px'},
    imageHeight: {xs: '216px', sm: '408px', md: '407px'},
    gradient: <ThirdGradientBox/>,
    triangle: TriangleDownRight
  },
  {
    title: 'Обмен средств',
    description: [
      'Проводите эффективные обмены средствами между разными валютами. Система одинаково хорошо работает как с фиатными валютами, так и с криптовалютами. Конвертируйте свои активы эффективно внутри вашего кошелька, чтобы удовлетворить ваши потребности без необходимости использования внешних обменников.',
    ],
    image: FourthImg,
    imageWidth: {xs: '310px', sm: '584px', md: '580px'},
    imageHeight: {xs: '211px', sm: '397px', md: '396px'},
    comingSoon: true,
    gradient: (
      <>
        <FourthGradientBox/>
        <FifthGradientBox/>
      </>
    ),
    triangle: TriangleUpLeft
  },
  {
    title: 'Вывод средств',
    description: [
      'Легко выводите средства из своих кошельков, используя внешние платежные системы, такие как Stripe, PayPal, Wise и др., а также криптовалюты  BTC, ETH и др.',
      'Для дополнительной безопасности каждую заявку на вывод должен подтвердить администратор системы через Панель администратора.',
      'Кроме того, настройте параметры автоматического вывода для заявок, соответствующих определенным критериям, таким как сумма или валюта запроса, чтобы автоматизировать процесс и повысить операционную эффективность.',
    ],
    image: FifthImg,
    imageWidth: {xs: '310px', sm: '584px', md: '579px'},
    imageHeight: {xs: '220px', sm: '386px', md: '385px'},
    triangle: TriagleUpRight
  },
  {
    title: 'Планирование платежей и переводов',
    description: [
      'Планируйте и выполняйте платежи и переводы по заранее установленному графику. Идеально подходит для регулярных платежей, таких как зарплаты, подписки или повторяющиеся сервисные сборы. Наша система позволяет настроить и забыть о запланированных переводах, вы можете быть уверены, что средства всегда будут там, где это необходимо.',
    ],
    image: SixthImg,
    imageWidth: {xs: '310px', sm: '584px', md: '570px'},
    imageHeight: {xs: '204px', sm: '384px', md: '384px'},
    gradient: <SixthGradientBox/>,
    triangle: TriangleDownLeft,
    comingSoon: true,
  },
];

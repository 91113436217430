import {motion, useMotionValue, useTransform, animate} from "framer-motion";
import {useEffect} from "react";

export const Counter = ({number}: { number: number }) => {
  const count = useMotionValue(0);
  const rounded = useTransform(count, Math.round);

  useEffect(() => {
    const animation = animate(count, number, {duration: 2.5});

    return animation.stop;
  }, []);

  return <motion.span
    style={{
      margin: '0',
    }}>{rounded}</motion.span>;
}

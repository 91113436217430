import {
  FirstGradientBox,
  SecondGradientBox,
  ThirdGradientBox,
} from 'components/gradient-boxes/ForFeaturePage';

import FirstImg from 'assets/features/crypto/firstImg.png';
import SecondImg from 'assets/features/crypto/secondImg.png';
import ThirdImg from 'assets/features/crypto/thirdImg.png';
import TriagleUpRight from 'assets/features/triangles/triangleUpRight.png';
import TriangleDownRight from 'assets/features/triangles/triangleDownRight.png';
import TriangleDownLeft from 'assets/features/triangles/triangleDownLeft.png';

export const headerInfoForFeaturesPages = {
  info: 'Предустановленная интеграция с популярными блокчейн-решениями, такими как BTC, ETH, LTC, TRX и др. для обработки криптовалютных транзакций.',
  title: 'Операции с криптовалютами и управление активами',
  details: 'Комплексные инструменты управления криптовалютами',
};

export const contentInfoForFeaturesPages = [
  {
    title: 'Интеграция с криптовалютными сетями',
    description: [
      'Поддержка подключения к основным криптовалютным сетям предоставляет широкий спектр возможностей для криптовалютных транзакций.',
    ],
    image: FirstImg,
    imageWidth: {xs: '310px', sm: '584px', md: '598px'},
    imageHeight: {xs: '226px', sm: '425px', md: '434px'},
    gradient: (
      <>
        <FirstGradientBox/>
        <SecondGradientBox/>
      </>
    ),
    triangle: TriagleUpRight
  },
  {
    title: 'Поддержка NFT и активов',
    description: [
      'Будущие обновления включают поддержку основных стандартов NFT (ERC 721 и ERC1155), что подходит для различных бизнес-моделей, таких как продажа билетов и торговые услуги.',
    ],
    comingSoon: true,
    image: SecondImg,
    imageWidth: {xs: '310px', sm: '584px', md: '597px'},
    imageHeight: {xs: '204px', sm: '383px', md: '391px'},
    triangle: TriangleDownLeft
  },
  {
    title: 'Настраиваемые криптовалютные операции',
    description: [
      'Настройка криптовалютных операций, включая установку параметров, таких как тип сети и IP-адреса узлов для надежного контроля.',
    ],
    image: ThirdImg,
    imageWidth: {xs: '310px', sm: '584px', md: '586px'},
    imageHeight: {xs: '211px', sm: '398px', md: '400px'},
    gradient: <ThirdGradientBox/>,
    triangle: TriangleDownRight,
    comingSoon: true,
  },
];

import React from 'react';

import {Box, Typography} from '@mui/material';

import {FAQAccordion} from 'components/home/faq/FaqAccordion/FaqAccordion';

interface IAccordionContent {
  question: string;
  answers: string[];
}

const ACCORDION_CONTENT: IAccordionContent[] = [
  {
    question: 'Что такое  FinTechSDK?',
    answers: [
      'FinTechSDK - это платформа, представляющая собой набор разработки программного обеспечения (SDK) и API для создания финансовых приложений и сервисов. Она включает мультивалютные кошельки, бухгалтерскую книгу, обработку финансовых транзакций и аналитику, а также поддерживает различные транзакции, в том числе депозиты, платежи, переводы и снятие средств.  FinTechSDK предлагает настраиваемые транзакции, такие как эскроу и выставление счетов. В систему включены модули интеграции внешних платежных систем. Cлужба авторизации SSO на основе KeyCloak обеспечивает строгий контроль ролей для повышения безопасности.',
    ],
  },
  {
    question: 'Как начать работу?',
    answers: [
      'Чтобы начать работу с  FinTechSDK, просто свяжитесь с нашей командой, и мы настроим систему под ваши требования. Мы предоставляем услуги разработки финтех-программного обеспечения,  электронных кошельков,  приложений для P2P-платежей.  Мы сформируем выделенную команду разработчиков для удовлетворения конкретных потребностей вашего проекта.\n',
    ],
  },
  {
    question: 'Какие отрасли вы обслуживаете? ',
    answers: [
      'Мы предоставляем наши услуги стартапам, предприятиям и разработчикам в различных отраслях. Независимо от того, являетесь вы небольшим стартапом, крупным предприятием или интегратором, у нас есть опыт для удовлетворения ваших требований и предоставления высококачественных решений.',
    ],
  },
  {
    question: 'Как с вами связаться?',
    answers: [
      'Вы можете использовать любые доступные способы связи, указанные в разделе "Контакты". Мы будем рады помочь вам.',
    ],
  },
  {
    question: 'Еще есть вопросы?',
    answers: ['Связаться с нами'],
  },
];
export const HomeFAQ = () => {
  return (
    <Box
      sx={{
        background: '#F9FAFB',
        minHeight: {xs: '730px', sm: '701px', md: '777px'},
        padding: {
          xs: '72px 16px 72px 16px',
          sm: '72px 20px 72px 20px',
          md: '100px 24px 100px 24px',
        },
        boxSizing: 'border-box',
      }}
      id="faq"
    >
      <Box
        sx={{
          minHeight: '577px',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '1307px',
          margin: 'auto',
          position: 'relative',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: {xs: '30px', sm: '32px', md: '52px'},
              fontWeight: {xs: 700, md: 800},
              lineHeight: {xs: '36px', sm: '41.6px', md: '62.4px'},
              textAlign: 'center',
            }}
          >
            Часто задаваемые вопросы (FAQs)
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: '16px', sm: '18px'},
              fontWeight: {xs: 400},
              lineHeight: {xs: '24px', sm: '27px'},
              textAlign: {xs: 'left', sm: 'center'},
              marginTop: '8px',
            }}
          >
            Найдите ответы на распространенные вопросы о предложениях FinTechSDK
          </Typography>
        </Box>
        <Box sx={{mt: '56px'}}></Box>
        {ACCORDION_CONTENT.map((item, ind) => (
          <FAQAccordion key={ind} question={item.question} answers={item.answers}/>
        ))}
      </Box>
    </Box>
  );
};

import ContentHeaderForFeaturesPage from 'components/features/ContentHeaderForFeaturesPages';
import ContentBoxForFeaturesPages from 'components/features/ContentBoxForFeaturesPages';

import {contentInfoForFeaturesPages, headerInfoForFeaturesPages} from './consts';
import AnimatedContentBoxForFeaturesPage from 'components/features/AnimatedContentBoxForFeaturesPage';
import {useGSAP} from '@gsap/react';
import gsap from 'gsap';
import {Box} from '@mui/material';
import {useMediaSize} from 'hooks/useDeviceDetector';
import {gsapHandlerCard} from 'utils/gsapHandler';
import {SlidingCards} from 'components/features/SlidingCards';

gsap.registerPlugin(useGSAP);

export const MobileWalletTab = () => {
  const {isMobileDevice} = useMediaSize();
  const images: {
    image: string;
    width: string;
  }[] = contentInfoForFeaturesPages.map((content: {
    image: string;
    imageWidth: { md: string }
  }) => ({
    image: content.image,
    width: content.imageWidth.md,
  }));
  useGSAP(() => {
    if (!isMobileDevice) {
      gsap.set(['.slideImages0'], {
        x: 100,
        display: 'none',
        opacity: 0,
      });
      gsap.set(['.slideImages2', '.slideImages4'], {
        x: 100,
        display: 'none',
        opacity: 0,
      });
      gsap.set(['.slideImages1', '.slideImages3'], {
        x: -100,
        display: 'none',
        opacity: 0,
      });
      gsap.set(['.slideImages5'], {
        x: -100,
        display: 'none',
        opacity: 0,
      });

      gsapHandlerCard('.slideImages0', '.triggerImages0', '200', '-1000 650px', '800 650px');
      gsapHandlerCard('.slideImages1', '.triggerImages1', '-200');
      gsapHandlerCard('.slideImages2', '.triggerImages2', '200');
      gsapHandlerCard('.slideImages3', '.triggerImages3', '-200');
      gsapHandlerCard('.slideImages4', '.triggerImages4', '200');
      gsapHandlerCard('.slideImages5', '.triggerImages5', '-200', '0 650px', '3000 650px');
    }

  }, {dependencies: [isMobileDevice]});
  return (
    <Box>
      <ContentHeaderForFeaturesPage headerInfo={headerInfoForFeaturesPages}/>
      {!isMobileDevice && <SlidingCards images={images}/>}
      {contentInfoForFeaturesPages.map((content, index) =>
        <Box
          key={content.title}
          className={`triggerImages${index}`}
        >
          {!isMobileDevice && (
            <AnimatedContentBoxForFeaturesPage
              isLastIndex={contentInfoForFeaturesPages.length - 1 === index}
              index={index}
              content={content}
              reverse={!!(index % 2)}
            />
          )}
          {isMobileDevice && (
            <ContentBoxForFeaturesPages
              content={content}
              reverse={!!(index % 2)}
              isBottomBorder={index !== contentInfoForFeaturesPages.length - 1}
            />
          )}
        </Box>
      )}
    </Box>
  );
};


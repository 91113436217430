import {useGSAP} from '@gsap/react';
import gsap from 'gsap';

import {Box} from '@mui/material';

import ContentBoxForFeaturesPages from 'components/features/ContentBoxForFeaturesPages';
import ContentHeaderForFeaturesPages from 'components/features/ContentHeaderForFeaturesPages';

import AnimatedContentBoxForFeaturesPage from 'components/features/AnimatedContentBoxForFeaturesPage';
import {SlidingCards} from 'components/features/SlidingCards';
import {useMediaSize} from 'hooks/useDeviceDetector';
import {contentInfoForFeaturesPages, headerInfoForFeaturesPages} from './consts';
import {gsapHandlerCard} from 'utils/gsapHandler';

gsap.registerPlugin(useGSAP);
export const CryptoTab = () => {
  const {isMobileDevice} = useMediaSize();
  const images: {
    image: string;
    width: string;
  }[] = contentInfoForFeaturesPages.map((content) => ({
    image: content.image,
    width: content.imageWidth.md,
  }));
  useGSAP(() => {
    if (!isMobileDevice) {
      gsap.set(['.slideImages0'], {
        x: 100,
        display: 'none',
        opacity: 0,
      });
      gsap.set(['.slideImages2'], {
        x: 100,
        display: 'none',
        opacity: 0,
      });
      gsap.set(['.slideImages1'], {
        x: -100,
        display: 'none',
        opacity: 0,
      });


      gsapHandlerCard('.slideImages0', '.triggerImages0', '200', '-1000 650px', '800 650px');
      gsapHandlerCard('.slideImages1', '.triggerImages1', '-200');
      gsapHandlerCard('.slideImages2', '.triggerImages2', '200', '0 650px', '3000 650px');
    }

  }, {dependencies: [isMobileDevice]});
  return (
    <Box>
      <ContentHeaderForFeaturesPages headerInfo={headerInfoForFeaturesPages}/>
      {!isMobileDevice && <SlidingCards images={images}/>}
      <Box>
        {contentInfoForFeaturesPages.map((content, index) => (
          <Box key={content.title} className={`triggerImages${index}`}>
            {!isMobileDevice && (
              <AnimatedContentBoxForFeaturesPage
                isLastIndex={contentInfoForFeaturesPages.length - 1 === index}
                index={index}
                content={content}
                reverse={!!(index % 2)}
              />
            )}
            {isMobileDevice && (
              <ContentBoxForFeaturesPages
                key={content.title}
                content={content}
                reverse={!!(index % 2)}
                isBottomBorder={index !== contentInfoForFeaturesPages.length - 1}
              />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

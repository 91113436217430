import { useMediaSize } from 'hooks/useDeviceDetector';
import React from 'react';

import { Box } from '@mui/material';

import BackgroundAnimation from 'components/common/Balls';
import { PortfolioSection } from 'components/portfolio/PortfolioSection';

import MetaAdsAvatarImg from 'assets/portfolio/projects/metaAds/metaAdsAvatar.png';
import MetaAdsCard_1_Img from 'assets/portfolio/projects/metaAds/metaAdsCard_1.png';
import MetaAdsCard_2_Img from 'assets/portfolio/projects/metaAds/metaAdsCard_2.png';
import MetaAdsCard_3_Img from 'assets/portfolio/projects/metaAds/metaAdsCard_3.png';
import MetaAdsCard_4_Img from 'assets/portfolio/projects/metaAds/metaAdsCard_4.png';
import TixnetAvatarImg from 'assets/portfolio/projects/tixnet/tixnetAvatar.png';
import TixnetCard_1_Img from 'assets/portfolio/projects/tixnet/tixnetCard_1.png';
import TixnetCard_2_Img from 'assets/portfolio/projects/tixnet/tixnetCard_2.png';
import TixnetCard_3_Img from 'assets/portfolio/projects/tixnet/tixnetCard_3.png';
import TixnetCard_4_Img from 'assets/portfolio/projects/tixnet/tixnetCard_4.png';
import ShopiviewAvatarImg from 'assets/portfolio/projects/videoReview/videoReviewAvatar.png';
import Shopiview_1_Img from 'assets/portfolio/projects/videoReview/videoReviewCard_1.png';
import Shopiview_2_Img from 'assets/portfolio/projects/videoReview/videoReviewCard_2.png';
import Shopiview_3_Img from 'assets/portfolio/projects/videoReview/videoReviewCard_3.png';
import Shopiview_4_Img from 'assets/portfolio/projects/videoReview/videoReviewCard_4.png';

export interface PortfolioProject {
  title: string;
  description: string;
  section: {
    avatar: string;
    fullName: string;
    sectionDescription: string;
    images: string[];
    link?: string;
    linkedin?: string;
  };
}

export const PORTFOLIO_PROJECTS = [
  {
    title: 'Tixnet',
    description:
      'Tixnet - это решение для первичной продажи билетов организаторами мероприятий. С Tixnet вы можете управлять и организовывать свои мероприятия, продавать билеты на них, чтобы оптимизировать продажи и прибыль. Tixnet выделяется как первая бесплатная глобальная торговая площадка для билетов, предлагающая уникальную многоязычную и многовалютную платформу, адаптированную для местных пользователей. Оцените удобство и эффективность Tixnet для всех ваших потребностей в продаже билетов.',
    section: {
      avatar: TixnetAvatarImg,
      fullName: 'CEO TixNet Атле Барлауп',
      sectionDescription:
        'Мы работаем с  FinTechSDK несколько месяцев. Мы восхищаемся и ценим их профессионализм и отличный сервис. Их платформа предоставляет нам все инструменты, необходимые для эффективного управления нашими финансами, обработки платежей и выставления счетов нашим клиентам. Мы ценим их гибкий подход к интеграции с различными платежными системами, что позволяет нам предлагать удобные и безопасные способы оплаты билетов.  FinTechSDK предоставляет аналитические и отчетные возможности, которые помогают нам понимать потребности наших клиентов. Это позволяет нам принимать обоснованные решения для оптимизации нашего бизнеса. Мы рекомендуем их услуги всем, кто ищет надежного партнера в области финансовых технологий.',
      images: [TixnetCard_1_Img, TixnetCard_2_Img, TixnetCard_3_Img, TixnetCard_4_Img],
      link: 'https://staging-tixnet.eastrelay.com/ ',
      linkedin: 'https://www.linkedin.com/in/atle-barlaup-4b7ba416/',
    },
  },

  {
    title: 'Meta Ads',
    description:
      'Meta Ads - это передовая рекламная платформа, разработанная для метавселенных, иммерсивных миров и дополненной, виртуальной и смешанной реальности. Она фокусируется на перформанс-маркетинге, аналитике и точном таргетинге, предоставляя комплексные решения для охвата аудитории в быстро развивающемся ландшафте AR, VR и MR.',
    section: {
      avatar: MetaAdsAvatarImg,
      fullName: 'CEO Meta Ads Ярослав Воловой',
      sectionDescription:
        'Мы успешно интегрировали  FinTechSDK в наш продукт, создав систему платежей и распределения, которая поддерживает как фиатные, так и криптовалютные транзакции. Их решения постоянно демонстрируют современность и безопасность, делая их высоко ценными партнерами, которых мы можем смело рекомендовать.',
      images: [MetaAdsCard_1_Img, MetaAdsCard_2_Img, MetaAdsCard_3_Img, MetaAdsCard_4_Img],
      link: 'https://metaads.team/',
      linkedin: 'https://www.linkedin.com/in/yaroslavvolovoj/',
    },
  },

  {
    title: 'Shopiview',
    description:
      'Сервис Shopiview предоставляет уникальную возможность пользователям оставлять платные отзывы о продуктах на различных торговых площадках. Платформа создана с идеей, что каждое мнение имеет ценность и стремится создать простую и эффективную систему вознаграждения за полезные отзывы. Компании, в свою очередь, получают доступ к качественным и объективным отзывам, а пользователи имеют возможность заработать дополнительный доход за свое участие. Shopiview создает справедливую и прозрачную среду для обмена мнениями, где выигрывают обе стороны.',
    section: {
      avatar: ShopiviewAvatarImg,
      fullName: 'Павел Волков, основатель',
      sectionDescription:
        'Мы приняли стратегическое решение не тратить время на разработку собственной бухгалтерской книги и функционала для оплаты наших услуг, а использовать решение  FinTechSDK. Это позволило нам значительно сократить время разработки и оперативно выйти на рынок. Благодаря  FinTechSDK мы можем эффективно управлять оплатой услуг и распределением денежных вознаграждений среди пользователей нашей системы, обеспечивая надежность и безопасность всех транзакций.',
      images: [Shopiview_1_Img, Shopiview_2_Img, Shopiview_3_Img, Shopiview_4_Img],
      link: '',
      linkedin: '',
    },
  },
];

export const PortfolioProjects = () => {
  const { isPhone } = useMediaSize();
  return (
    <Box
      sx={{
        maxWidth: '100%',
        position: 'relative',
      }}
    >
      <BackgroundAnimation speed={isPhone ? 100 : 150} blur={isPhone ? 90 : 190} diameter={isPhone ? 250 : 500}  countOfBalls={isPhone ? 5 : 7} zIndex={-1} />
      {PORTFOLIO_PROJECTS.map((project, ind) => (
        <PortfolioSection key={project.title + ind} ind={ind} project={project} />
      ))}
    </Box>
  );
};

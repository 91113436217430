import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Button from 'components/common/Button';
import ContentBox from 'components/common/ContentBox';

import {scrollHandler} from 'utils/scrollHandler';
import {useMediaSize} from "hooks/useDeviceDetector";

const AddModule = () => {
  const {isMobileDevice} = useMediaSize();

  return (
    <Box pb="100px" sx={{p: {xs: '36px 16px', sm: '48px 20px', md: '75px 24px'}, mt: isMobileDevice ? '' : '400px'}}>
      <ContentBox
        flexDirection={{xs: 'column', sm: 'row'}}
        gap={3}
        p={{xs: '24px', md: '50px'}}
        border="1px rgba(82, 96, 254, 0.8) solid"
        borderRadius="20px"
      >
        <Typography
          sx={{
            fontSize: {xs: '30px', sm: '32px', md: '52px'},
            fontWeight: {xs: 700, md: 800},
            lineHeight: {xs: '36px', sm: '41.6px', md: '62.4px'},
            color: '#5260FE',
          }}
        >
          Добавьте свой индивидуальный модуль
        </Typography>
        <Box sx={{width: {xs: '100%', sm: '70%'}}}>
          <Typography
            sx={{
              fontSize: {xs: '16px', md: '18px'},
              fontWeight: {xs: 400},
              lineHeight: {xs: '24px', md: '27px'},
              mb: {xs: '24px', md: '32px'},
            }}
          >
            Мы разработаем специализированные модули, адаптированные к вашему продукту. Если модуль
            принесет пользу нашей системе, мы не будем взимать за него плату.
          </Typography>
          <Button
            onClick={(e) => {
              scrollHandler(e, 'demo');
            }}
            sx={{width: {xs: '100%', sm: '140px'}}}
          >
            Добавить модуль
          </Button>
        </Box>
      </ContentBox>
    </Box>
  );
};

export default AddModule;

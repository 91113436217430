import {
  FifthGradientBox,
  FirstGradientBox,
  FourthGradientBox,
  SecondGradientBox,
  SixthGradientBox,
  ThirdGradientBox,
} from 'components/gradient-boxes/ForFeaturePage';

import FifthImg from 'assets/features/mobile/fifthImg.png';
import FirstImg from 'assets/features/mobile/firstImg.png';
import FourthImg from 'assets/features/mobile/fourthImg.png';
import SecondImg from 'assets/features/mobile/secondImg.png';
import SixthImg from 'assets/features/mobile/sixthImg.png';
import ThirdImg from 'assets/features/mobile/thirdImg.png';
import TriagleUpRight from 'assets/features/triangles/triangleUpRight.png';
import TriangleUpLeft from 'assets/features/triangles/triangleUpLeft.png';
import TriangleDownRight from 'assets/features/triangles/triangleDownRight.png';
import TriangleDownLeft from 'assets/features/triangles/triangleDownLeft.png';

export const headerInfoForFeaturesPages = {
  info: 'Вся мощь  FinTechSDK в Вашем смартфоне. Разработанное для платформ iOS и Android, наше мобильное приложение повторяет сложные функциональные возможности веб-версии, предоставляя пользователям возможность бесшовного использования системы.',
  title: 'Мобильный кошелек',
  details: ' Ваш телефон: швейцарский нож в мире финтеха - всегда с вами, всегда функционален',
};

export const contentInfoForFeaturesPages = [
  {
    title: 'Универсальная совместимость',
    description: [
      'Разработанное на Flutter кроссплатформенное решение опубликовано в Apple Store и Google Play. Это обеспечивает доступность ваших финансовых инструментов на самых популярных мобильных платформах.',
    ],
    image: FirstImg,
    imageWidth: {xs: '165px', sm: '237px', md: '420px'},
    imageHeight: {xs: '254px', sm: '336px', md: '648px'},
    gradient: (
      <>
        <FirstGradientBox/>
        <SecondGradientBox/>
      </>
    ),
    triangle: TriagleUpRight
  },
  {
    title: 'White Label решение',
    description: [
      'Запустите приложение под своим брендом. Полностью настраиваемое приложение обеспечит удовлетворение потребностей Ваших клиентов.',
    ],
    image: SecondImg,
    imageWidth: {xs: '169px', sm: '238px', md: '460px'},
    imageHeight: {xs: '253px', sm: '355px', md: '687px'},
    triangle: TriangleDownLeft
  },
  {
    title: 'Полная интеграция функций',
    description: [
      'Все основные функции нашей веб-платформы, включая управление мультивалютными счетами, выставление счетов, платежи и переводы, интегрированы в мобильное приложение в виде SDK.',
    ],
    image: ThirdImg,
    imageWidth: {xs: '177px', sm: '238px', md: '510px'},
    imageHeight: {xs: '254px', sm: '341px', md: '729px'},
    gradient: <ThirdGradientBox/>,
    triangle: TriangleDownRight
  },
  {
    title: 'Усиленная безопасность',
    description: [
      'Наше приложение использует современные технологии безопасности для защиты Ваших транзакций и конфиденциальной информации. Мы обеспечим спокойствие Вам и Вашим пользователям.',
    ],
    image: FourthImg,
    imageWidth: {xs: '183px', sm: '237px', md: '429px'},
    imageHeight: {xs: '254px', sm: '329px', md: '597px'},
    gradient: (
      <>
        <FourthGradientBox/>
        <FifthGradientBox/>
      </>
    ),
    triangle: TriangleUpLeft
  },
  {
    title: 'Удобный интерфейс',
    description: [
      'Наш мобильный кошелек разработан с учетом пользовательского опыта, что обеспечивает интуитивную навигацию и понятный интерфейс, делая управление финансами простым и беззаботным.',
    ],
    image: FifthImg,
    imageWidth: {xs: '182px', sm: '237px', md: '480px'},
    imageHeight: {xs: '254px', sm: '332px', md: '674px'},
    triangle: TriagleUpRight
  },
  {
    title: 'Уведомления в реальном времени',
    description: [
      'Оставайтесь в курсе  изменений кошелька, проведенных транзакций и обновлений безопасности, получая уведомления в режиме реального времени через мобильное приложение.',
    ],
    image: SixthImg,
    imageWidth: {xs: '121px', sm: '158px', md: '291px'},
    imageHeight: {xs: '258px', sm: '337px', md: '627px'},
    gradient: <SixthGradientBox/>,
    triangle: TriangleDownLeft
  },
];

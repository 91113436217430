export interface TermsConditionsParagraphs {
  title: string;
  description: string[];
}

export const TERMS_CONDITIONS_PARAGRAPHS: TermsConditionsParagraphs[] = [
  {
    title: '1. Общие условия',
    description: [
      '1.1 Принятие условий: Используя Платформу, вы соглашаетесь соблюдать и быть связанными этими Условиями использования.',
      '1.2 Изменения условий: Мы оставляем за собой право изменять эти Условия в любое время. Любые изменения будут опубликованы на Платформе, и вы несете ответственность за периодический просмотр этих Условий. Продолжение использования Платформы означает принятие обновленных Условий.',
    ],
  },
  {
    title: '2. Использование платформы',
    description: [
      '2.1 Право на использование: Платформа может использоваться только лицами, обладающими правоспособностью заключать обязательные контракты в соответствии с законодательством своей страны проживания. Используя Платформу, вы заявляете, что достигли совершеннолетия в вашей стране проживания.',
      '2.2 Запрещенные использования: Вы не можете использовать Платформу для каких-либо незаконных или несанкционированных целей. Это включает, но не ограничивается, нарушением законов вашей юрисдикции, нарушением наших прав интеллектуальной собственности или прав других лиц, а также передачей вредоносного или злонамеренного кода.',
    ],
  },
  {
    title: '3. Учетная запись и безопасность',
    description: [
      '3.1 Создание учетной записи: Для использования определенных функций Платформы вам может потребоваться создать учетную запись. Вы должны предоставить точную и полную информацию при создании своей учетной записи.',
      '3.2 Безопасность учетной записи: Вы несете ответственность за сохранение конфиденциальности информации вашей учетной записи, включая ваш пароль, а также за все действия, которые происходят под вашей учетной записью. Вы соглашаетесь немедленно уведомить нас о любом несанкционированном использовании вашей учетной записи.',
    ],
  },
  {
    title: '4. Конфиденциальность',
    description: [
      '4.1 Сбор данных: Мы собираем, используем и делимся вашими данными, как описано в нашей Политике конфиденциальности. Используя Платформу, вы соглашаетесь с сбором и использованием этой информации, как указано в Политике конфиденциальности.',
      '4.2 Безопасность данных: Мы внедряем меры безопасности для защиты ваших данных, но не можем гарантировать, что несанкционированные третьи стороны никогда не смогут обойти эти меры или использовать ваши данные для неправильных целей.',
    ],
  },
  {
    title: '5. Интеллектуальная собственность',
    description: [
      '5.1 Право собственности: Все материалы на Платформе, включая, но не ограничиваясь, текст, графику, логотипы и программное обеспечение, являются интеллектуальной собственностью ООО "ТЕЗЛ" и защищены применимым законодательством об интеллектуальной собственности.',
      '5.2 Лицензия: Мы предоставляем вам ограниченную, непередаваемую, неисключительную лицензию на доступ и использование Платформы для личных некоммерческих целей. Вы не можете копировать, изменять, распространять или продавать любую часть Платформы или ее содержимого без нашего предварительного письменного согласия.',
    ],
  },
  {
    title: '6. Ссылки на сторонние ресурсы',
    description: [
      'Платформа может содержать ссылки на сторонние веб-сайты или услуги, которые не принадлежат нам и не контролируются нами. Мы не несем ответственности за контент, политику конфиденциальности или практику любых сторонних веб-сайтов или услуг.',
    ],
  },
  {
    title: '7. Ограничение ответственности',
    description: [
      'В максимально возможной степени, разрешенной законом, ООО "ТЕЗЛ" не несет ответственности за любые косвенные, случайные, специальные, последующие или штрафные убытки или любые потери прибыли или доходов, понесенные прямо или косвенно, или любые потери данных, использования, доброй воли или других нематериальных потерь, возникшие в результате использования вами Платформы.',
    ],
  },
  {
    title: '8. Возмещение убытков',
    description: [
      'Вы соглашаетесь возместить, защитить и удерживать ООО "ТЕЗЛ" и его филиалов, должностных лиц, директоров, сотрудников и агентов от любых претензий, убытков, обязательств, расходов или долгов и расходов, возникающих в результате вашего использования Платформы или нарушения вами этих Условий.',
    ],
  },
  {
    title: '9. Применимое право',
    description: [
      'Эти Условия регулируются и толкуются в соответствии с законодательством Республики Крым без учета его коллизионных норм.',
    ],
  },
  {
    title: '10. Прекращение',
    description: [
      'Мы оставляем за собой право прекратить или приостановить ваш доступ к Платформе немедленно, без предварительного уведомления или ответственности, по любой причине, включая, но не ограничиваясь, нарушение этих Условий.',
    ],
  },
  {
    title: '11. Свяжитесь с нами',
    description: [
      'Если у вас есть вопросы относительно этих Условий, пожалуйста, свяжитесь с нами по адресу support@fintechsdk.ru.',
    ],
  },
  {
    title: '12. Полное соглашение',
    description: [
      'Эти Условия представляют собой полное соглашение между вами и ООО "ТЕЗЛ" относительно использования Платформы и заменяют собой любые предыдущие соглашения между вами и ООО "ТЕЗЛ" относительно использования Платформы.',
    ],
  },
];

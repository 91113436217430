import {useGSAP} from '@gsap/react';
import {gsap} from 'gsap';
import {FC, ReactNode} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ContentBox from 'components/common/ContentBox';
import GradientBox from 'components/common/GradientBox';
import {gsapHandlerText} from 'utils/gsapHandler';

gsap.registerPlugin(useGSAP);

interface ContentInfoForFeaturesPages {
  title: string;
  description: string[];
  image: string;
  comingSoon?: boolean;
  gradient?: ReactNode;
  imageHeight: { xs?: string; sm?: string; md?: string; lg?: string };
  imageWidth: { xs?: string; sm?: string; md?: string; lg?: string };
  triangle?: string;
}

interface Props {
  content: ContentInfoForFeaturesPages;
  reverse: boolean;
  isBottomBorder?: boolean;
  index?: number;
  isLastIndex?: boolean;
}

const ContentBoxForFeaturesPages: FC<Props> = ({
                                                 content,
                                                 reverse,
                                                 index,
                                                 isLastIndex,
                                               }) => {
  useGSAP(() => {
    gsap.set(
      ['.slideText0,.slideText1,.slideText2,.slideText3,.slideText4,.slideText5,.slideText6'],
      {
        opacity: 0,
      }
    );
    gsapHandlerText('.slideText0', '.triggerImages0', '-1000 650px')
    gsapHandlerText('.slideText1', '.triggerImages1')
    gsapHandlerText('.slideText2', '.triggerImages2')
    gsapHandlerText('.slideText3', '.triggerImages3')
    gsapHandlerText('.slideText4', '.triggerImages4')
    gsapHandlerText('.slideText5', '.triggerImages5')
  });
  return (
    <Box sx={{height: isLastIndex ? 100 : 800}}>
      <GradientBox>
        <ContentBox
          sx={{
            maxWidth: '1308px',
            display: 'flex',
            flexDirection: {xs: 'column', md: reverse ? 'row-reverse' : 'row'},
            justifyContent: 'center',
            alignItems: 'center',
            gap: '24px',
            p: {xs: '36px 16px', sm: '48px 20px', md: '75px 24px'},
            boxSizing: 'border-box',
            position: 'relative',
          }}
        >
          <Box
            className={`slideText${index}`}
            sx={{maxWidth: {xs: '100%', md: '35%', zIndex: 10}}}
          >
            <Typography
              width="auto"
              sx={{
                fontSize: {xs: '18px', sm: '24px', md: '40px'},
                fontWeight: {xs: 700},
                lineHeight: {xs: '25.5px', sm: '33.6px', md: '48px'},
                color: '#1D3061',
              }}
            >
              {content.title}
              <sup style={{fontSize: '16px', fontWeight: '400'}}>
                {content.comingSoon && ' (скоро будет доступно)'}
              </sup>
            </Typography>
            {content.description.map((description, index) => (
              <Typography
                key={index}
                sx={{
                  fontSize: {xs: '14px', md: '16px'},
                  fontWeight: {xs: 400},
                  lineHeight: {xs: '21px', md: '24px'},
                  mt: {xs: '16px'},
                  textAlign: 'justify',
                }}
              >
                {description}
              </Typography>
            ))}
          </Box>
          {content.gradient}
          <Box
            position={'absolute'}
            sx={{
              mr: reverse ? 100 : -100,
            }}
            component="img"
            height="auto"
            width={300}
            src={content.triangle}
          />
          <Box
            sx={{
              width: {md: '50%'},

            }}
          ></Box>
        </ContentBox>
      </GradientBox>
    </Box>
  );
};

export default ContentBoxForFeaturesPages;

import {backgroundGradient} from 'consts/style';
import Lottie from 'lottie-react';
import React, {FC, useEffect, useState} from 'react';
import LazyLoad from 'react-lazyload';

import {Box, Typography} from '@mui/material';

interface IProps {
  img: any;
  text: string;
  description?: string;
}

export const Animation: FC<IProps> = ({img, text, description}) => {
  const [isPlay, setIsPlay] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false);

  const handleMouseEnter = () => {
    if (!hasPlayed) {
      setIsPlay(true);
      setHasPlayed(true);
    }
  };
  useEffect(() => {
    setIsPlay(false);
  }, [hasPlayed]);

  return (
    <Box
      key={text}
      sx={{
        textAlign: 'center',
        position: 'relative',
        p: '64px 8px 16px',
        width: '242px',
        // height: '149px',
        border: '1px solid',
        borderImageSlice: 1,
        borderColor: '#FFFFFF80',
        borderRadius: '20px',
        bgcolor: '#FFFFFF26',
        mt: 6,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        setIsPlay(false);
        setHasPlayed(false);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '-50px',
          left: '69px',
          width: '100px',
          height: '100px',
          bgcolor: 'blue',
          borderRadius: '50%',
          background: backgroundGradient,
          backgroundBlendMode: 'multiply, normal',
        }}
      >
        <LazyLoad>
          <Lottie
            style={{marginLeft: '5px', marginTop: '9px'}}
            animationData={img}
            autoplay={true}
            loop={isPlay}
          />
        </LazyLoad>{' '}
      </Box>
      <Typography
        sx={{
          fontSize: {xs: '16px', sm: '18px', md: '18px'},
          fontWeight: {xs: 600, md: 500},
          lineHeight: {xs: '24px', sm: '27px', md: '27px'},
          color: '#FFFFFF',
        }}
      >
        {text}
      </Typography>
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '18px',
          color: '#FFFFFF',
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

import {ILink} from "../pages/cookies-settings/consts";
import React from "react";
import {Link} from "react-router-dom";
import {theme} from "../theme";

export const parseLinks = (text: string, links: ILink[]) => {
  let remainingText = text;
  const elements: React.ReactNode[] = [];

  links.forEach(link => {
    const {phrase, url} = link;
    const index = remainingText.indexOf(phrase);
    if (index !== -1) {
      const before = remainingText.substring(0, index);
      if (before) elements.push(<span key={`${url}-before`}>{before}</span>);
      elements.push(
        <Link key={url} to={url} target='_blank' style={{color: theme.palette.text.secondary}}>
          {phrase}
        </Link>
      );
      remainingText = remainingText.substring(index + phrase.length);
    }
  });

  if (remainingText) elements.push(<span key="remaining">{remainingText}</span>);

  return elements;
};
import {FC} from 'react';

import Box, {BoxProps} from '@mui/material/Box';

const ContentBox: FC<BoxProps> = ({children, ...props}) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          maxWidth: '1308px',
        }}
        {...props}
        margin="auto"
      >
        {children}
      </Box>
    </Box>
  );
};

export default ContentBox;

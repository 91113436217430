import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Modal } from '@mui/material';

interface IProps {
  images: {
    image: string;
    width: string;
  }[];
}

export const SlidingCards: FC<IProps> = ({ images }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [popupImage, setPopupImage] = useState<string>('');
  const location = useLocation();
  const currentPage = location.pathname;
  return (
    <Box
      position="sticky"
      zIndex={30}
      top={'20vh'}
      sx={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}
    >
      {images.map((image, index) => {
        return (
          <Box
            className={`slideImages${index}`}
            zIndex={40}
            key={index}
            sx={{
              perspective: '1000px',
              position: 'absolute',
              top: index === 0 ? '0px' : index === images.length - 1 ? '-110px' : '',
            }}
          >
            <Box
              onClick={() => {
                setModalIsOpen(true);
                setPopupImage(image.image);
              }}
              component="img"
              src={image.image}
              sx={{
                width: image.width,
                height: 'auto',
                transform:
                  index % 2 ? 'rotateX(10deg) rotateY(5deg)' : 'rotateX(10deg) rotateY(-5deg)',
                transformOrigin:
                  index % 2 ? 'rotateX(10deg) rotateY(5deg)' : 'rotateX(10deg) rotateY(-5deg)',
                cursor: 'pointer',
              }}
            />
          </Box>
        );
      })}
      <Modal
        open={modalIsOpen}
        onClick={() => {
          setModalIsOpen(false);
        }}
        disableScrollLock={false}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          component="img"
          src={popupImage}
          sx={{
            width: currentPage !== '/features/mobile-wallet' ? '55vw' : 'auto',
            height: currentPage === '/features/mobile-wallet' ? '95vh' : 'auto',
            border: 'none',
            outline: 'none',
          }}
        />
      </Modal>
    </Box>
  );
};

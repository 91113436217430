import {
  FifthGradientBox,
  FirstGradientBox,
  FourthGradientBox,
  SecondGradientBox,
  SixthGradientBox,
  ThirdGradientBox,
} from 'components/gradient-boxes/ForFeaturePage';

import FifthImg from 'assets/features/invoicing/fifthImg.png';
import FirstImg from 'assets/features/invoicing/firstImg.png';
import FourthImg from 'assets/features/invoicing/fourthImg.png';
import SecondImg from 'assets/features/invoicing/secondImg.png';
import SixthImg from 'assets/features/invoicing/sixthImg.png';
import ThirdImg from 'assets/features/invoicing/thirdImg.png';
import TriagleUpRight from 'assets/features/triangles/triangleUpRight.png';
import TriangleUpLeft from 'assets/features/triangles/triangleUpLeft.png';
import TriangleDownRight from 'assets/features/triangles/triangleDownRight.png';
import TriangleDownLeft from 'assets/features/triangles/triangleDownLeft.png';

export const headerInfoForFeaturesPages = {
  info: 'Инструменты для управления и учета финансовых транзакций включают расчеты с поставщиками, выставление счетов, эскроу-счета, реферальные программы, программы лояльности и скидок.',
  title: 'Выставление счетов и настраиваемые операции',
  details: 'Подстройте бизнес-логику под свои нужды',
};

export const contentInfoForFeaturesPages = [
  {
    title: 'Выставление счетов',
    description: [
      'Генерируйте и управляйте счетами в системе бесшовно. Эта функция является неотъемлемой для бизнеса, требующего последовательного и точного учета выставления счетов и отслеживания платежей.',
    ],
    image: FirstImg,
    imageWidth: {xs: '310px', sm: '584px', md: '590px'},
    imageHeight: {xs: '227px', sm: '427px', md: '438px'},
    gradient: (
      <>
        <FirstGradientBox/>
        <SecondGradientBox/>
      </>
    ),
    triangle: TriagleUpRight,
    comingSoon: true,
  },
  {
    title: 'Эскроу-счета',
    description: [
      'Защищайте финансы с помощью эскроу-счетов, которые высвобождают средства только при выполнении заранее определенных условий. Идеально подходит для транзакций, требующих проверки или выполнения определенных условий.',
    ],
    image: SecondImg,
    imageWidth: {xs: '310px', sm: '584px', md: '642px'},
    imageHeight: {xs: '231px', sm: '436px', md: '465px'},
    triangle: TriangleDownLeft
  },

  {
    title: 'Настраиваемые платежные потоки',
    description: [
      'Настройте процессы платежей под уникальные бизнес-модели. Эта функция необходима для управления разнообразными сценариями платежей, которые могут различаться в зависимости от клиента или типа транзакции.',
    ],
    image: ThirdImg,
    imageWidth: {xs: '310px', sm: '584px', md: '578px'},
    imageHeight: {xs: '200px', sm: '375px', md: '375px'},
    gradient: <ThirdGradientBox/>,
    triangle: TriangleDownRight
  },
  {
    title: 'Реферальные программы',
    description: [
      'Внедряйте и управляйте реферальными программами, которые поощряют существующих клиентов привлекать новых, способствуя росту бизнеса и привлечению клиентов.',
    ],
    image: FourthImg,
    imageWidth: {xs: '310px', sm: '584px', md: '580px'},
    imageHeight: {xs: '225px', sm: '424px', md: '419px'},
    gradient: (
      <>
        <FourthGradientBox/>
        <FifthGradientBox/>
      </>
    ),
    triangle: TriangleUpLeft,
    comingSoon: true,
  },
  {
    title: 'Программы лояльности и скидок',
    description: [
      'Разрабатывайте и управляйте программами лояльности и скидок, чтобы удерживать клиентов и увеличивать продажи. Эти программы могут быть настроены в соответствии с маркетинговыми стратегиями и тенденциями потребительского поведения.',
    ],
    image: FifthImg,
    imageWidth: {xs: '310px', sm: '584px', md: '586px'},
    imageHeight: {xs: '228px', sm: '432px', md: '431px'},
    triangle: TriagleUpRight
  },
  {
    title: 'Платежи продавцам и поставщикам',
    description: [
      'Автоматизируйте и точно рассчитывайте платежи продавцам и поставщикам. Этот инструмент важен для бизнеса, который зависит от сети поставщиков и партнеров.',
    ],
    image: SixthImg,
    imageWidth: {xs: '310px', sm: '584px', md: '581px'},
    imageHeight: {xs: '218px', sm: '413px', md: '412px'},
    gradient: <SixthGradientBox/>,
    triangle: TriangleDownLeft
  },
];

import {emailRegexp, requiredMessage} from 'validation';
import * as yup from 'yup';

export const CONTACT_SCHEMA = yup.object().shape({
  name: yup.string().trim().max(100, 'Максимально 100 символов').required(requiredMessage),
  email: yup
    .string()
    .trim()
    .required(requiredMessage)
    .matches(emailRegexp, 'Должна быть электронная почта'),
  phone: yup.string().trim(),
  message: yup.string().trim().max(500, 'Максимально 500 символов').required(requiredMessage),
  accept: yup
    .boolean()
    .required('Должен быть выбран')
    .test('accept', 'Должен быть выбран', (value: any) => {
      if (value === true) return true;
    }),
});

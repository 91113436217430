import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, useTheme } from '@mui/material';

import Button from 'components/common/Button';
import { PortfolioCard } from 'components/home/portfolio/PortfolioCard/PortfolioCard';

import MetaAdsImg from 'assets/home/potfolio/metaAds.png';
import TixNetImg from 'assets/home/potfolio/tixNet.png';
import ShopiviewImg from 'assets/home/potfolio/videoReview.png';

interface ICardContent {
  image: string;
  title: string;
  description: string;
  tags: string[];
}

const CARD_CONTENT: ICardContent[] = [
  {
    image: TixNetImg,
    title: 'TixNet',
    description: 'Платформа для продажи билетов, управления и организации мероприятий',
    tags: ['#Управление', '#Билетерия', '#Платформа'],
  },
  {
    image: MetaAdsImg,
    title: 'Meta Ads',
    description: 'Платформа для маркетинга и рекламы в иммерсивных мирах',
    tags: ['#Иммерсивная', '#Реклама', '#Платформа'],
  },
  {
    image: ShopiviewImg,
    title: 'Shopiview',
    description: 'Платформа для заказа оплачиваемых видео отзывов на маркетплейсах',
    tags: ['#ВидеоОтзывы', '#ПлатныеОтзывы', '#Платформа'],
  },
];

export const HomePortfolio = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      className={'home_portfolio_section'}
      sx={{
        position: 'relative',
        // background: '#F9FAFB',
        padding: {
          xs: '72px 16px 72px 16px',
          sm: '72px 20px 72px 20px',
          md: '100px 24px 100px 24px',
        },
        zIndex: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '1312px',
          margin: 'auto',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        {/*<Box*/}
        {/*  sx={{*/}
        {/*    position: 'absolute',*/}
        {/*    zIndex: 0,*/}
        {/*    borderRadius: '50%',*/}
        {/*    width: '1101px',*/}
        {/*    height: '1101px',*/}
        {/*    top: '-43px',*/}
        {/*    left: '-703.71px',*/}
        {/*    opacity: '0px',*/}
        {/*    angle: '-90 deg',*/}
        {/*    background:*/}
        {/*      'radial-gradient(circle,rgba(82, 96, 254, 0.5) 0%, rgba(82, 96, 254, 0) 70%)',*/}
        {/*  }}*/}
        {/*/>*/}
        <Box
          className={'home_portfolio_titles'}
          sx={{ height: '148px', maxWidth: '768px', margin: 'auto' }}
        >
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '27px',
              textAlign: 'center',
            }}
          >
            Портфолио
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '30px', sm: '32px', md: '52px' },
              fontWeight: { xs: 700, md: 800 },
              lineHeight: { xs: '36px', sm: '41.6px', md: '62.4px' },
              textAlign: 'center',
              marginTop: '24px',
            }}
          >
            Наши проекты
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '16px', sm: '18px' },
              fontWeight: { xs: 400 },
              lineHeight: { xs: '24px', sm: '27px' },
              textAlign: 'center',
              marginTop: '12px',
            }}
          >
            Откройте для себя мощь наших финтех-решений в действии
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: '56px',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: { xs: '20px', md: '24px' },
          }}
        >
          {CARD_CONTENT.map((card) => (
            <PortfolioCard
              key={card.title}
              title={card.title}
              description={card.description}
              tags={card.tags}
              image={card.image}
            />
          ))}
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={{
            minWidth: { xs: '100%', sm: '212px' },
            height: '48px',
            borderRadius: '15px',
            border: `1px ${theme.palette.primary.main} solid`,
            mt: '64px',
            alignSelf: 'center',
          }}
          onClick={() => {
            navigate('/portfolio');
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
              textAlign: 'center',
            }}
          >
            Все проекты
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

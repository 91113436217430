import EnterpriseAdvantageThird from 'assets/serve/animations/enterprise/ComprehensiveWhiteLabel.json';
import EnterpriseAdvantageFourth from 'assets/serve/animations/enterprise/DedicatedDevelopmentTeam.json';
import EnterpriseAdvantageFifth from 'assets/serve/animations/enterprise/PostPurchaseSupport.json';
import EnterpriseAdvantageFirst from 'assets/serve/animations/enterprise/SwiftDeployment.json';
import EnterpriseAdvantageSecond from 'assets/serve/animations/enterprise/VendorIndependent.json';
import StartupAdvantageFirst from 'assets/serve/animations/sartUp/AffordableStartIcon.json';
import StartupAdvantageFifth from 'assets/serve/animations/sartUp/FlexibleCancellationPolicy.json';
import StartupAdvantageFourth from 'assets/serve/animations/sartUp/ProductDevelopmentShortcut.json';
import StartupAdvantageSecond from 'assets/serve/animations/sartUp/RegulatoryComplianceAutomation.json';
import StartupAdvantageThird from 'assets/serve/animations/sartUp/StreamlinedIntegrations.json';

interface AdvantagesInfo {
  img: any;
  text: string;
  description?: string;
}

export const advantagesStartupsInfo: AdvantagesInfo[] = [
  {
    img: StartupAdvantageFirst,
    text: 'Доступное начало',
  },
  {
    img: StartupAdvantageSecond,
    text: 'Ускоренная разработка продукта',
  },
  {
    img: StartupAdvantageThird,
    text: 'Упрощенные интеграции',
  },
  {
    img: StartupAdvantageFourth,
    text: 'Автоматизация соблюдения нормативных требований',
  },
  {
    img: StartupAdvantageFifth,
    text: 'Гибкая политика отмены подписки',
  },
];

export const advantagesEnterprisesInfo: AdvantagesInfo[] = [
  {
    img: EnterpriseAdvantageFirst,
    text: 'Быстрое развертывание',
  },
  {
    img: EnterpriseAdvantageSecond,
    text: 'Независимость от поставщиков',
    description: '(мы передадим код и базу знаний вашей команде)',
  },
  {
    img: EnterpriseAdvantageThird,
    text: 'Комплексное White Label решение',
  },
  {
    img: EnterpriseAdvantageFourth,
    text: 'Выделенная команда разработчиков',
  },
  {
    img: EnterpriseAdvantageFifth,
    text: 'Поддержка после покупки',
  },
];

import LazyLoad from 'react-lazyload';

import {Box, Typography} from '@mui/material';

import ContentBox from 'components/common/ContentBox';
import GradientBox from 'components/common/GradientBox';
// import {FirstGradientBox, SecondGradientBox} from 'components/gradient-boxes/ForServePage';

import StartupsImg from 'assets/serve/startups.png';

const Startups = () => {
  return (
    <GradientBox id="Startups">
      <ContentBox
        sx={{
          maxWidth: '1308px',
          display: 'flex',
          flexDirection: {xs: 'column', sm: 'row'},
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          padding: {
            xs: '72px 16px 72px 16px',
            sm: '72px 20px 72px 20px',
            md: '100px 24px 100px 24px',
          },
        }}
      >
        {/*<FirstGradientBox/>*/}
        {/*<SecondGradientBox/>*/}
        <Box display="flex" justifyContent="center">
          <LazyLoad height={400} offset={100} once>
            <Box
              sx={{
                maxWidth: {xs: '343px', sm: '351px', md: '464px'},
                minHeight: {xs: '339px', sm: '347px', md: '669px'},
              }}
              component="img"
              src={StartupsImg}
              height="auto"
              alt="Startups"
            />
          </LazyLoad>
        </Box>
        <Box
          sx={{
            maxWidth: {
              xs: '342px',
              sm: '357px',
              md: '643px',
            },
          }}
        >
          <Typography
            sx={{
              fontSize: {xs: '30px', sm: '40px', md: '52px'},
              fontWeight: {xs: 700, md: 800},
              lineHeight: {xs: '36px', sm: '48px', md: '62.4px'},
              color: '#1D3061',
            }}
          >
            Стартапы
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: '14px', md: '16px'},
              fontWeight: {xs: 400},
              lineHeight: {xs: '21px', md: '24px'},
              mt: '16px',
            }}
          >
            Наши решения адаптированы для удовлетворения уникальных потребностей стартапов,
            предоставляя им инструменты и поддержку, необходимые для процветания на конкурентном рынке.
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: '14px', md: '16px'},
              fontWeight: {xs: 400},
              lineHeight: {xs: '21px', md: '24px'},
              mt: {xs: '4px', md: '16px'},
            }}
          >
            Мы используем комплексный подход, включающий тщательный анализ, консультации,
            определение бизнес-потребностей, настройку, запуск и постоянную поддержку,
            обеспечивая стартапам руководство и помощь, необходимые для успеха.
          </Typography>
        </Box>
      </ContentBox>
    </GradientBox>
  );
};

export default Startups;

import {FC, ReactNode} from 'react';
import LazyLoad from 'react-lazyload';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ContentBox from 'components/common/ContentBox';
import GradientBox from 'components/common/GradientBox';

interface ContentInfoForFeaturesPages {
  title: string;
  description: string[];
  image: string;
  comingSoon?: boolean;
  gradient?: ReactNode;
  imageHeight: { xs?: string; sm?: string; md?: string; lg?: string };
  imageWidth: { xs?: string; sm?: string; md?: string; lg?: string };
}

interface Props {
  content: ContentInfoForFeaturesPages;
  reverse: boolean;
  isBottomBorder: boolean;
}

const ContentBoxForFeaturesPages: FC<Props> = ({content, reverse, isBottomBorder}) => {
  return (
    <GradientBox>
      <ContentBox
        sx={{
          maxWidth: '1308px',
          display: 'flex',
          flexDirection: {xs: 'column', md: reverse ? 'row-reverse' : 'row'},
          justifyContent: 'center',
          alignItems: 'center',
          gap: '24px',
          p: {xs: '36px 16px', sm: '48px 20px', md: '75px 24px'},
          borderBottom: isBottomBorder ? '1px #5260FECC solid' : 'none',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <Box sx={{maxWidth: {xs: '100%', md: '50%', zIndex: 10,}}}>
          <Typography
            width="auto"
            sx={{
              fontSize: {xs: '18px', sm: '24px', md: '40px'},
              fontWeight: {xs: 700},
              lineHeight: {xs: '25.5px', sm: '33.6px', md: '48px'},
              color: '#1D3061',
            }}
          >
            {content.title}
            <sup style={{fontSize: '16px', fontWeight: '400'}}>
              {content.comingSoon && ' (скоро)'}
            </sup>
          </Typography>
          {content.description.map((description, index) => (
            <Typography
              key={index}
              sx={{
                fontSize: {xs: '14px', md: '16px'},
                fontWeight: {xs: 400},
                lineHeight: {xs: '21px', md: '24px'},
                mt: {xs: '16px'},
                textAlign: 'justify',
              }}
            >
              {description}
            </Typography>
          ))}
        </Box>
        {content.gradient}
        <Box
          sx={{
            width: {md: '50%'},
            minHeight: {
              xs: content.imageHeight.xs,
              sm: content.imageHeight.sm,
              md: content.imageHeight.md,
            },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10,
          }}
        >
          <LazyLoad height={400} offset={100} once>
            <Box
              component="img"
              src={content.image}
              sx={{
                width: {
                  xs: content.imageWidth.xs,
                  sm: content.imageWidth.sm,
                  md: content.imageWidth.md,
                },
                height: 'auto',
              }}
            />
          </LazyLoad>
        </Box>
      </ContentBox>
    </GradientBox>
  );
};

export default ContentBoxForFeaturesPages;

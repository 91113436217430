import {FC} from 'react';

import {Box, Typography} from '@mui/material';

import ContentBox from 'components/common/ContentBox';
import GradientBox from 'components/common/GradientBox';
// import {
//   FifthGradientBox,
//   SixthGradientBox,
//   ThirdGradientBox,
// } from 'components/gradient-boxes/ForServePage';

import {enterprisesMethodologiesInfo, startupsMethodologiesInfo} from './consts';

interface Props {
  isStartupsMethodologies?: boolean;
}

const Methodologies: FC<Props> = ({isStartupsMethodologies}) => {
  const methodologiesInfo = isStartupsMethodologies
    ? startupsMethodologiesInfo
    : enterprisesMethodologiesInfo;
  const methodologiesTitle = isStartupsMethodologies
    ? 'Процесс работы со стартапами'
    : 'Процесс работы с предприятиями';

  return (
    <GradientBox>
      <ContentBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          padding: {
            xs: '0 16px 72px 16px',
            sm: '0 20px 72px 20px',
            md: '0 24px 100px 24px',
          },
        }}
      >
        {/*//{isStartupsMethodologies ? (*/}
        {/* // <ThirdGradientBox/>*/}
        {/*//) : (*/}
        {/* // <>*/}
        {/*  //  <FifthGradientBox/>*/}
        {/*   // <SixthGradientBox/>*/}
        {/*  //</>*/}
        {/*// )}*/}

        <Box sx={{maxWidth: {xs: '343px', sm: '541px', md: '642px'}}}>
          <Typography
            sx={{
              fontSize: {xs: '20px', sm: '24px', md: '30px'},
              fontWeight: {xs: 700},
              lineHeight: {xs: '28px', sm: '33.6px', md: '41.6px'},
              textAlign: 'center',
            }}
          >
            {methodologiesTitle}
          </Typography>
          {methodologiesInfo.map((methodology) => (
            <Box
              key={methodology.id}
              sx={{
                mt: {xs: '36px', sm: '48px', md: '36px'},
                position: 'relative',
                pl: {xs: '29px', md: '32px'},
                '&:not(:last-child)': {
                  '&::before': {
                    content: "''",
                    position: 'absolute',
                    top: 18,
                    left: -1,
                    width: '1px',
                    height: {xs: '128%', sm: '148%', md: '138%'},
                    backgroundColor: '#5260FE',
                  },
                },
                '&::after': {
                  content: "''",
                  position: 'absolute',
                  top: 18,
                  left: 0,
                  width: 18,
                  height: 18,
                  borderRadius: '50%',
                  backgroundColor: '#5260FE',
                  transform: 'translate(-50%, -50%)',
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {xs: '14px', sm: '20px', md: '24px'},
                  fontWeight: {xs: 700},
                  lineHeight: {xs: '21px', sm: '28px', md: '33.6px'},
                  ml: '8px',
                }}
              >
                {methodology.id}. {methodology.title}
              </Typography>
              <Typography textAlign="justify" mt={1}>
                {methodology.text}
              </Typography>
            </Box>
          ))}
        </Box>
      </ContentBox>
    </GradientBox>
  );
};

export default Methodologies;

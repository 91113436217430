export interface PrivacyPolicyParagraph {
  title: string;
  description: string[];
}

export interface ILink {
  phrase: string;
  url: string;
}

export const PRIVACY_POLICY_PARAGRAPHS: PrivacyPolicyParagraph[] = [
  {
    title: '1. Информация, которую мы собираем',
    description: [
      '1.1 Персональная информация: Мы можем собирать персональную информацию, такую как ваше имя, адрес электронной почты, номер телефона и платежная информация при создании учетной записи или использовании наших услуг.',
      '1.2 Данные об использовании: Мы собираем информацию о том, как вы используете Платформу, такие как посещаемые страницы, используемые функции и выполняемые действия.',
      '1.3 Информация об устройстве: Мы собираем информацию об устройстве, которое вы используете для доступа к Платформе, включая тип устройства, операционную систему и тип браузера.',
      '1.4 Данные о местоположении: Мы можем собирать и обрабатывать информацию о вашем фактическом местоположении, используя различные технологии, такие как IP-адрес, GPS и другие сенсоры.',
    ],
  },
  {
    title: '2. Как мы используем вашу информацию',
    description: [
      '2.1 Предоставление услуг: Мы используем вашу информацию для предоставления, поддержания и улучшения наших услуг, включая персонализацию вашего опыта на Платформе.',
      '2.2 Коммуникация: Мы используем вашу информацию для связи с вами, включая отправку сообщений, обновлений и рекламных предложений, связанных с нашими услугами.',
      '2.3 Безопасность: Мы используем вашу информацию для защиты безопасности и целостности нашей Платформы и для предотвращения и выявления мошенничества и злоупотреблений.',
      '2.4 Соответствие: Мы используем вашу информацию для выполнения юридических обязательств, таких как проведение проверок KYC/AML/CFT.',
    ],
  },
  {
    title: '3. Обмен вашей информацией',
    description: [
      '3.1 Сторонние поставщики услуг: Мы можем делиться вашей информацией с сторонними поставщиками услуг, которые выполняют услуги от нашего имени, такие как обработка платежей и анализ данных.',
      '3.2 Юридические требования: Мы можем раскрыть вашу информацию, если это требуется законом или в ответ на юридические процедуры, такие как судебный приказ или повестка.',
      '3.3 Бизнес-передачи: Мы можем делиться или передавать вашу информацию в связи с или в ходе переговоров о любом слиянии, продаже активов компании, финансировании или приобретении всего или части нашего бизнеса другой компанией.',
    ],
  },
  {
    title: '4. Безопасность данных',
    description: [
      'Мы внедряем меры безопасности для защиты вашей информации от несанкционированного доступа, использования или раскрытия. Однако ни один метод передачи данных через Интернет или электронного хранения не является 100% безопасным, и мы не можем гарантировать абсолютную безопасность.',
    ],
  },
  {
    title: '5. Ваши права',
    description: [
      '5.1 Доступ и исправление: Вы имеете право на доступ и исправление вашей персональной информации, хранящейся у нас. Вы можете обновить информацию вашей учетной записи через Платформу или связавшись с нами напрямую.',
      '5.2 Удаление данных: Вы имеете право запросить удаление вашей персональной информации. Пожалуйста, обратите внимание, что мы можем сохранить определенную информацию, как того требует закон или для законных деловых целей.',
      '5.3 Отказ от подписки: Вы имеете право отказаться от получения рекламных сообщений от нас, следуя инструкциям по отмене подписки в этих сообщениях или связавшись с нами напрямую.',
    ],
  },
  {
    title: '6. Файлы cookie и технологии отслеживания',
    description: [
      `Мы используем файлы cookie и аналогичные технологии отслеживания для сбора и использования персональной информации о вас, включая предоставление рекламы на основе ваших интересов. Для получения дополнительной информации о типах используемых нами файлов cookie и о том, как управлять ими, пожалуйста, ознакомьтесь с нашей Политикой использования файлов cookie.`,
    ],
  },
  {
    title: '7. Международные передачи данных',
    description: [
      'Ваша информация может быть передана и сохранена на компьютерах, расположенных за пределами вашего штата, провинции, страны или другой государственной юрисдикции, где законы о защите данных могут отличаться от законов в вашей юрисдикции. Используя нашу Платформу, вы соглашаетесь на такие передачи.',
    ],
  },
  {
    title: '8. Изменения в этой Политике конфиденциальности',
    description: [
      'Мы можем время от времени обновлять эту Политику конфиденциальности. Любые изменения будут опубликованы на этой странице, и мы рекомендуем вам периодически пересматривать эту Политику конфиденциальности. Продолжение использования Платформы после внесения изменений означает принятие новой Политики конфиденциальности.',
    ],
  },
  {
    title: '9. Свяжитесь с нами',
    description: [
      'Если у вас есть вопросы или сомнения по поводу этой Политики конфиденциальности, пожалуйста, свяжитесь с нами по адресу support@fintechsdk.ru.',
    ],
  },
  {
    title: '10. Применимое право',
    description: [
      'Настоящая Политика конфиденциальности регулируется и толкуется в соответствии с законодательством Республики Крым без учета его коллизионных норм.',
      'Используя Платформу FinTechSDK.ru, вы соглашаетесь с условиями этой Политики конфиденциальности. Если вы не согласны с этими условиями, пожалуйста, не используйте Платформу.',
    ],
  },
];

export const PRIVACY_POLICY_PARAGRAPHS_LINKS: ILink[] = [
  {phrase: 'Политикой использования файлов cookie', url: '/cookies-settings'},
  {phrase: 'FinTechSDK.ru', url: 'https://fintechsdk.ru/'},
];

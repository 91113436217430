import React from 'react';
import {parseLinks} from 'utils/parseLinks';

import {Box, Typography} from '@mui/material';
import {Link} from "react-router-dom";
import {theme} from "theme";
import {COOKIES_SETTINGS_PARAGRAPHS, COOKIES_SETTINGS_PARAGRAPHS_LINKS} from './consts';

export const CookiesSettingsPage = () => {
  return (
    <Box sx={{p: '100px 24px'}}>
      <Box sx={{maxWidth: '1303px', margin: 'auto'}}>
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: 700,
            lineHeight: '41.6px',
            textAlign: 'center',
          }}
        >
          Политика использования файлов cookie <Link
          to='https://www.fintechsdk.ru/'
          style={{
            color: theme.palette.text.primary
          }}> FinTechSDK.ru.</Link>
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '21px',
            textAlign: 'left',
            mt: '56px',
          }}
        >
          Последнее обновление: 1 мая 2024 г.
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            textAlign: 'left',
          }}
        >
          Настоящая Политика использования файлов cookie объясняет, <Link
          to='https://www.fintechsdk.ru/'
          style={{
            color: theme.palette.text.primary
          }}> FinTechSDK.ru. </Link>
          , управляемый и принадлежащий ООО {`"ТЕЗЛ"(далее "Мы", "Нас", "Наш")`}, использует файлы cookie и аналогичные
          технологии отслеживания на нашей платформе {`("Платформа")`}. Используя Платформу, вы соглашаетесь с
          использованием файлов cookie, как это описано в данной политике.

        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '24px', mt: '24px'}}>
          {COOKIES_SETTINGS_PARAGRAPHS.map((paragraph) => (
            <Box key={paragraph.title}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '28px',
                  textAlign: 'left',
                }}
              >
                {paragraph.title}
              </Typography>
              <Box sx={{display: 'flex', flexDirection: 'column', mt: '16px', gap: '8px'}}>
                {paragraph.description.map((item) => (
                  <Typography
                    key={item}
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '21px',
                      textAlign: 'left',
                    }}
                  >
                    {parseLinks(item, COOKIES_SETTINGS_PARAGRAPHS_LINKS)}
                  </Typography>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

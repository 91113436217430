export interface CookieSettingsParagraphs {
  title: string;
  description: string[];
}

export interface ILink {
  phrase: string;
  url: string;
}


export const COOKIES_SETTINGS_PARAGRAPHS: CookieSettingsParagraphs[] = [
  {
    title: '1. Что такое файлы cookie?',
    description: [
      'Файлы cookie — это небольшие текстовые файлы, которые размещаются на вашем устройстве веб-сайтами, которые вы посещаете. Они широко используются для обеспечения работы веб-сайтов или их более эффективной работы, а также для предоставления информации владельцам сайта.',
    ]
  },
  {
    title: '2. Типы файлов cookie, которые мы используем',
    description: [
      'Мы используем следующие типы файлов cookie на нашей Платформе:',
      '2.1 Необходимые файлы cookie: Эти файлы cookie необходимы для работы Платформы. Например, они включают файлы cookie, которые позволяют вам входить в защищенные зоны нашей Платформы.',
      '2.2 Аналитические/файлы cookie производительности: Эти файлы cookie позволяют нам распознавать и подсчитывать количество посетителей, а также видеть, как посетители перемещаются по нашей Платформе во время ее использования. Это помогает нам улучшать работу нашей Платформы, например, обеспечивая пользователям легкость нахождения нужной информации.',
      '2.3 Функциональные файлы cookie: Эти файлы cookie используются для распознавания вас, когда вы возвращаетесь на нашу Платформу. Это позволяет нам персонализировать наш контент для вас, приветствовать вас по имени и запоминать ваши предпочтения (например, выбор языка или региона).',
      '2.4 Целевые файлы cookie: Эти файлы cookie записывают ваш визит на нашу Платформу, страницы, которые вы посетили, и ссылки, которые вы следовали. Мы будем использовать эту информацию, чтобы сделать нашу Платформу и рекламу, отображаемую на ней, более релевантной вашим интересам. Мы также можем делиться этой информацией с третьими сторонами для этих целей.'
    ]
  },
  {
    title: '3. Как мы используем файлы cookie',
    description: [
      'Мы используем файлы cookie для:',
      '• Предоставления и улучшения наших услуг.',
      '• Понимания и улучшения производительности и удобства использования Платформы.',
      '• Персонализации вашего опыта, запоминая ваши предпочтения.',
      '• Предоставления релевантной рекламы и отслеживания ее эффективности.',
      '• Поддержания безопасности и целостности нашей Платформы.'

    ]
  },
  {
    title: '4. Файлы cookie третьих сторон',
    description: [
      'Помимо наших собственных файлов cookie, мы можем использовать различные файлы cookie третьих сторон для отчета о статистике использования Платформы и предоставления рекламы. Эти файлы cookie третьих сторон регулируются политиками конфиденциальности соответствующих третьих сторон.',
    ]
  },
  {
    title: '5. Управление файлами cookie',
    description: [
      'Вы можете управлять или удалять файлы cookie, используя настройки вашего браузера. Вот как это сделать в некоторых популярных браузерах:',
      '• Настройки файлов cookie в Google Chrome: Chrome Cookie Settings',
      '• Настройки файлов cookie в Mozilla Firefox: Firefox Cookie Settings',
      '• Настройки файлов cookie в Safari: Safari Cookie Settings',
      '• Настройки файлов cookie в Microsoft Edge: Edge Cookie Settings',
      'Пожалуйста, обратите внимание, что если вы решите заблокировать файлы cookie, вы можете не иметь доступа ко всем частям нашей Платформы или некоторые функции могут работать некорректно.'
    ]
  },
  {
    title: '6. Изменения в этой Политике использования файлов cookie',
    description: [
      'Мы можем время от времени обновлять эту Политику использования файлов cookie. Любые изменения будут опубликованы на этой странице, и мы рекомендуем вам периодически пересматривать эту Политику использования файлов cookie. Продолжение использования Платформы после внесения изменений означает принятие новой Политики использования файлов cookie.',
    ]
  },
  {
    title: '7. Свяжитесь с нами',
    description: [
      'Если у вас есть вопросы или сомнения по поводу этой Политики использования файлов cookie, пожалуйста, свяжитесь с нами по адресу support@fintechsdk.ru.',
    ]
  },
]


export const COOKIES_SETTINGS_PARAGRAPHS_LINKS: ILink[] = [
  {
    phrase: 'Edge Cookie Settings',
    url: 'https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09'
  },
  {phrase: 'Safari Cookie Settings', url: 'https://support.apple.com/en-gb/guide/safari/sfri11471/mac'},
  {
    phrase: 'Firefox Cookie Settings',
    url: 'https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US'
  },
  {phrase: 'Chrome Cookie Settings', url: 'https://support.google.com/chrome/answer/95647'},
];
import FeaturesHeaderImg from 'assets/features/header/FeaturesHeaderImg.png';
import FeaturesHeaderMobileImg from 'assets/features/header/FeaturesHeaderMobileImg.jpg';
import FeaturesHeaderTabletImg from 'assets/features/header/FeaturesHeaderTabletImg.jpg';
import PortfolioHeaderImg from 'assets/portfolio/header/portfolioHeader.png';
import PortfolioHeaderMobileImg from 'assets/portfolio/header/portfolioHeaderMobile.jpg';
import PortfolioHeaderTabletImg from 'assets/portfolio/header/portfolioHeaderTablet.jpg';
import ServeHeaderImg from 'assets/serve/serveHeader.png';
import ServeHeaderMobileImg from 'assets/serve/serveHeaderMobile.jpg';
import ServeHeaderTabletImg from 'assets/serve/serveHeaderTablet.jpg';

export const gradientHeaderBoxContent = {
  features: {
    title: 'Создайте свой индивидуальный финансовый инструмент',
    details: 'Выберите нужные функции, и мы воплотим их в жизнь!',
    src: {
      desktop: FeaturesHeaderImg,
      tablet: FeaturesHeaderTabletImg,
      mobile: FeaturesHeaderMobileImg,
    },
  },
  serve: {
    title: 'С кем мы работаем?',
    details: 'Индивидуальные финансовые решения для стартапов, предприятий и разработчиков',
    src: {
      desktop: ServeHeaderImg,
      tablet: ServeHeaderTabletImg,
      mobile: ServeHeaderMobileImg,
    },
  },
  portfolio: {
    title: 'Нам доверяют',
    details:
      'Компании выбирают нас за надежные, эффективные и инновационные решения',
    src: {
      desktop: PortfolioHeaderImg,
      tablet: PortfolioHeaderTabletImg,
      mobile: PortfolioHeaderMobileImg,
    },
  },
};

import LazyLoad from 'react-lazyload';
import { theme } from 'theme';

import { Box, Link, Typography } from '@mui/material';

import { Counter } from 'components/common/Counter/Counter';

import FifthImg from 'assets/home/about/fifthImg.png';
import FirstImg from 'assets/home/about/firstImg.png';
import FourthImg from 'assets/home/about/fourthImg.png';
import SecondImg from 'assets/home/about/secondImg.png';
import ThirdImg from 'assets/home/about/thirdImg.png';

interface BottomArea {
  title: string;
  description: string;
  sign: string;
}

const BOTTOM_AREA: BottomArea[] = [
  {
    title: '2002',
    description: 'год основания',
    sign: '',
  },
  {
    title: '22',
    description: 'лет опыта',
    sign: '+',
  },
  {
    title: '1277',
    description: 'выполнено проектов',
    sign: '+',
  },
  {
    title: '64',
    description: 'человек в команде разработки',
    sign: '+',
  },
  {
    title: '97',
    description: 'Уровень рекомендаций',
    sign: '%',
  },
];

export const HomeAbout = () => {
  return (
    <Box
      className="home_about_section"
      sx={{
        position: 'relative',
        overflow: 'hidden',
        padding: {
          xs: '72px 16px 72px 16px',
          sm: '72px 20px 72px 20px',
          md: '100px 24px 100px 24px',
        },
        boxSizing: 'border-box',
        // background: 'white',
        zIndex: 2,
      }}
      id="about"
    >
      <Box sx={{ maxWidth: '1312px', margin: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 'auto',
            position: 'relative',
            gap: '32px',
          }}
        >
          {/*<Box*/}
          {/*  sx={{*/}
          {/*    position: 'absolute',*/}
          {/*    zIndex: '1',*/}
          {/*    width: '1098px',*/}
          {/*    height: '1098px',*/}
          {/*    top: '-300.87px',*/}
          {/*    right: '-900px',*/}
          {/*    gap: '0px',*/}
          {/*    opacity: '0px',*/}
          {/*    angle: '-90 deg',*/}
          {/*    borderRadius: '50%',*/}
          {/*    background:*/}
          {/*      'radial-gradient(circle,rgba(254, 124, 82, 0.45) 10% , rgba(254, 124, 82, 0) 45%)',*/}
          {/*  }}*/}
          {/*/>*/}
          {/*<Box*/}
          {/*  sx={{*/}
          {/*    position: 'absolute',*/}
          {/*    zIndex: '-9',*/}
          {/*    width: '1098px',*/}
          {/*    height: '1098px',*/}
          {/*    top: '-200.87px',*/}
          {/*    right: '-600px',*/}
          {/*    gap: '0px',*/}
          {/*    opacity: '0px',*/}
          {/*    angle: '-90 deg',*/}
          {/*    borderRadius: '50%',*/}
          {/*    background:*/}
          {/*      'radial-gradient(circle,rgba(200, 113, 253, 0.5) 10% , rgba(200, 113, 253, 0)50%)',*/}
          {/*  }}*/}
          {/*/>*/}
          <Box
            sx={{
              minHeight: { xs: '', sm: '', md: '605px' },
              width: { xs: '100%', md: '58%' },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '30px', sm: '32px', md: '52px' },
                fontWeight: { xs: 700, md: 800 },
                lineHeight: { xs: '36px', sm: '41.6px', md: '62.4px' },
                textAlign: { xs: 'center', sm: 'left' },
              }}
            >
              О нас
            </Typography>
            <Box
              sx={{
                minHeight: { xs: '', sm: '', md: '433px' },
                maxWidth: { xs: '100%', md: '710px' },
                mt: '56px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '21px',
                  textAlign: 'left',
                }}
              >
                Наших экспертов объединяет общая черта – страсть к обучению. Чтобы стать нашим
                экспертом, вы должны быть настоящим профессионалом, любить учиться, постоянно
                узнавать новое, чтобы быть на переднем крае технологий.
                <br />
                <br />
                Наши отношения с поставщиками услуг хостинга по всему миру позволяют не только
                создать оптимальное решение для наших клиентов, но и помочь в его развертывании и
                постоянной поддержке.
                <br />
                <br />
                Секрет успеха наших экспертов заключается в единстве и сплоченности нашей команды.
                Коллективный опыт наших 30 с лишним экспертов в каждой дисциплине процесса
                разработки программного обеспечения помогает гарантировать успех вашего проекта.
              </Typography>
              <Link
                style={{ borderBottom: 'none', display: 'inline-block', marginTop: '20px' }}
                href="https://www.tezzle.ru/"
                sx={{
                  fontSize: '20px',
                  fontWeight: 600,
                  lineHeight: '30px',
                  textAlign: 'left',
                  marginTop: 5,
                }}
                target="_blank"
              >
                tezzle.ru
              </Link>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              minHeight: { xs: '494px', sm: '781px', md: '791.84px' },
              width: { xs: '100%', sm: '80%', md: '42%' },
            }}
          >
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" alignItems="end" gap={2}>
                <Box
                  className="home_about_image"
                  component="img"
                  height="auto"
                  width="67%"
                  alt="About us"
                  src={FirstImg}
                />
                <Box
                  className="home_about_image"
                  component="img"
                  height="auto"
                  width="30%"
                  alt="About us"
                  src={SecondImg}
                />
              </Box>
              <Box
                className="home_about_image"
                component="img"
                height="auto"
                width="100%"
                alt="About us"
                src={ThirdImg}
              />
              <Box display="flex" alignItems="start" gap={2}>
                <Box
                  className="home_about_image"
                  component="img"
                  height="auto"
                  width="30%"
                  alt="About us"
                  src={FourthImg}
                />
                <Box
                  className="home_about_image"
                  component="img"
                  height="auto"
                  width="67%"
                  alt="About us"
                  src={FifthImg}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '20px',
            p: '50px 0 ',
          }}
        >
          {BOTTOM_AREA.map((item) => (
            <Box
              className="home_about_bottomarea"
              key={item.description}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '16px',
                flex: 1,
              }}
            >
              <LazyLoad>
                <Typography
                  sx={{
                    fontSize: { xs: '56px', sm: '64px' },
                    fontWeight: { xs: 700 },
                    lineHeight: { xs: '67.2px', sm: '76.8px' },
                    textAlign: 'end',
                    color: theme.palette.text.secondary,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Counter number={+item.title} />
                  {item.sign}
                </Typography>
              </LazyLoad>

              <Typography
                sx={{
                  fontSize: { xs: '16px', md: '14px' },
                  fontWeight: { xs: 400 },
                  lineHeight: { xs: '24px', md: '21px' },
                  textAlign: 'center',
                  color: theme.palette.text.secondary,
                }}
              >
                {item.description}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

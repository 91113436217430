import {FC} from 'react';

import Box, {BoxProps} from '@mui/material/Box';

const GradientBox: FC<BoxProps> = ({children, ...props}) => {
  return (
    <Box sx={{overflowX: 'clip', overflowY: 'visible', ...props.sx}} {...props}>
      {children}
    </Box>
  );
};

export default GradientBox;

import { useMediaSize } from 'hooks/useDeviceDetector';
import React, { FC, useEffect, useRef, useState } from 'react';
import LazyLoad from 'react-lazyload';
import SwiperCore from 'swiper';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'index.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, IconButton, Link, Modal, Typography, useTheme } from '@mui/material';

import Button from 'components/common/Button';

import { PortfolioProject } from 'pages/portfolio/Projects';

interface IProps {
  project: PortfolioProject;
  ind: number;
}

export const PortfolioSection: FC<IProps> = ({ project, ind }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [popupImage, setPopupImage] = useState<string[]>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const { isPhone, isMobileDevice } = useMediaSize();
  const theme = useTheme();
  const swiperRef = useRef<SwiperCore | null>(null);
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % popupImage.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + popupImage.length) % popupImage.length);
  };
  useEffect(() => {
    swiperRef?.current?.update();
  }, [JSON.stringify(project)]);
  return (
    <Box
      sx={{
        minHeight: '801px',
        maxWidth: '100%',
        margin: 'auto',
        padding: {
          xs: `72px 16px  ${ind===2 ? '72px' : '0'} 16px`,
          sm: `72px 20px  ${ind===2 ? '72px' : '0'} 20px`,
          md: `100px 24px ${ind===2 ? '100px' : '0'} 24px`,
        },
        boxSizing: 'border-box',
        // backgroundColor: ind % 2 ? '' : '#F9FAFB',
      }}
      id={project.title.replace(/\s+/g, '').toLowerCase()}
    >
      <Box
        className="portfolio_page_titles"
        sx={{
          minHeight: '153px',
          maxWidth: '1312px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: '24px',
          margin: 'auto',
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '30px', sm: '40px' },
            fontWeight: { xs: 700 },
            lineHeight: { xs: '36px', sm: '48px' },
            textAlign: 'left',
            color: '#1D3061',
          }}
        >
          {project.title}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '16px', md: '18px' },
            fontWeight: { xs: 400 },
            lineHeight: { xs: '21px', sm: '24px', md: '27px' },
            textAlign: 'left',
          }}
        >
          {project.description}
        </Typography>
      </Box>

      <Box
        sx={{
          maxWidth: '1309PX',
          minHeight: {xs:'815px', sm:'1000px', md:'500px' },
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column-reverse', md: ind % 2 ? 'row-reverse' : 'row' },
          flexWrap: 'nowrap',
          margin: 'auto',
          mt: '48px',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '15px',
          gap: '24px',
          p: { xs: '20px', sm: '32px' },
          zIndex: 3,
          background: 'white',
        }}
      >
        <Box
          sx={{
            maxWidth: '610PX',
            minHeight: '427px',
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            mt: { xs: '36px', sm: '48px', md: 0 },
          }}
        >
          <Box
            component="img"
            sx={{
              width: { xs: '64px', sm: '100px' },
              height: { xs: '64px', sm: '100px' },
            }}
            src={project.section.avatar}
            alt="avatar"
          />
          <Box
            sx={{
              maxWidth: '610px',
              minHeight: '231px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              mt: '24px',
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '20px', sm: '24px' },
                fontWeight: { xs: 700 },
                lineHeight: { xs: '28px', sm: '33.6px' },
                textAlign: 'left',
              }}
            >
              {project.section.fullName}
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '21px',
                textAlign: 'left',
              }}
            >
              {project.section.sectionDescription}
            </Typography>
          </Box>
          {project.title !== 'Shopiview' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'start',
                gap: '16px',
                mt: '36px',
                '@media (max-width: 375px)': { flexDirection: 'column', alignItems: 'center' },
              }}
            >
              <Link href={project.section.link} underline="none" target="_blank">
                <Button
                  sx={{
                    minWidth: { xs: '139px', md: '100px' },
                    height: '48px',
                    borderRadius: '15px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 600,
                      lineHeight: '24px',
                      textAlign: 'center',
                    }}
                  >
                    {project.title}
                  </Typography>
                </Button>
              </Link>
              <Link href={project.section.linkedin} underline="none" target="_blank">
                <Button
                  sx={{
                    minWidth: { xs: '139px', md: '100px' },
                    height: '48px',
                    borderRadius: '15px',
                    border: `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: theme.palette.background.default,
                    '&:hover': { backgroundColor: 'transparent' },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 600,
                      lineHeight: '24px',
                      textAlign: 'center',
                      color: theme.palette.primary.main,
                    }}
                  >
                    Linkedin
                  </Typography>
                </Button>
              </Link>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            maxWidth: { xs: '294px', sm: '512px' },
            maxHeight: { xs: '229px', sm: '400px' },
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            margin: 'auto',
            alignItems: 'center',
            mt: { xs: '20px', sm: '32px' },
            '@media (max-width: 375px)': { maxWidth: '200px' },
            '@media (max-width: 300px)': { display: 'none' },
          }}
        >
          {!isPhone && (
            <IconButton
              className={`swiper_button_prev_${ind}`}
              sx={{
                position: 'absolute',
                top: '45%',
                left: -30,
                transform: 'translateY(-50%)',
                opacity: isLoad ? 1 : 0,
              }}
              color="primary"
            >
              <ArrowBackIos />
            </IconButton>
          )}
          <Swiper
            ref={swiperRef}
            onSwiper={(swiper: any) => {
              swiperRef.current = swiper;
            }}
            style={{
              maxWidth: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: '10%',
            }}
            slidesPerView={1}
            spaceBetween={5}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            loop={false}
            pagination={{
              clickable: true,
            }}
            navigation={{
              prevEl: `.swiper_button_prev_${ind}`,
              nextEl: `.swiper_button_next_${ind}`,
            }}
            modules={[Pagination, Navigation, Autoplay]}
          >
            {project.section.images.map((image, i) => (
              <SwiperSlide
                key={i}
                style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
              >
                <Box
                  sx={{
                    maxWidth: { xs: '294px', sm: '512px' },
                    maxHeight: { xs: '229px', sm: '400px' },
                    '@media (max-width: 375px)': {
                      maxWidth: '200px',
                      display: 'flex',
                      justifyContent: 'center',
                    },
                  }}
                  width="100%"
                >
                  <LazyLoad height={400} offset={100} once>
                    <Box
                      onClick={() => {
                        setModalIsOpen(true);
                        setPopupImage(project.section.images);
                        setCurrentImageIndex(i);
                      }}
                      component="img"
                      sx={{
                        height: 'auto',
                        width: '100%',
                        alt: 'project',
                        cursor: !isMobileDevice ? 'pointer' : '',
                      }}
                      src={image}
                      onLoad={() => setIsLoad(true)}
                    />
                  </LazyLoad>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
          {!isPhone && (
            <IconButton
              className={`swiper_button_next_${ind}`}
              sx={{
                position: 'absolute',
                top: '45%',
                right: -35,
                transform: 'translateY(-50%)',
                opacity: isLoad ? 1 : 0,
              }}
              color="primary"
            >
              <ArrowForwardIos />
            </IconButton>
          )}
        </Box>
      </Box>
      {!isMobileDevice && (
        <Modal
          open={modalIsOpen}
          onClick={() => {
            setModalIsOpen(false);
          }}
          disableScrollLock={false}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'none',
              outline: 'none',
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                nextImage();
              }}
              color="primary"
              sx={{
                width: '60px',
                height: '60px',
                fontSize: '40px',
                mr: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ArrowBackIos fontSize="inherit" sx={{ ml: '10px' }} />
            </IconButton>
            <Box
              component="img"
              src={popupImage[currentImageIndex]}
              sx={{
                width: '50vw',
                height: 'auto',
                border: 'none',
                outline: 'none',
              }}
            />
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                prevImage();
              }}
              color="primary"
              sx={{
                width: '60px',
                height: '60px',
                fontSize: '40px',
                ml: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ArrowForwardIos fontSize="inherit" />
            </IconButton>
          </Box>
        </Modal>
      )}
    </Box>
  );
};
